@import 'styles/helpers/variables.scss';

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 12px;

  &__title {
    display: inline-block;
    color: var(--text-color);
    text-wrap: wrap;
    @include font-14-bold;
  }

  &__info {
    display: flex;
    gap: 6px;
    color: var(--holder-color);

    span {
      display: flex;
      align-items: center;
      gap: 6px;
      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: var(--accent-second);
      }
    }

    .header__cost {
      gap: 0px;
    }
  }
}
