.global-icon {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;

  svg {
    height: 100%;
    width: 100%;
  }
}
