@import 'styles/helpers/variables.scss';

.item-wrapper {
  @include font-14;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  color: var(--text-color);

  &_hovered {
    background: var(--accent-third);
  }

  svg {
    path {
      fill: var(--accent-color);
    }
  }
}

.item-content {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
