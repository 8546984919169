@import 'styles/helpers/variables.scss';

.modal {
  padding: 40px;
  &__title {
    display: inline-block;
    color: var(--text-color);
    @include font-24-bold;
    margin-bottom: 24px;
  }
}
