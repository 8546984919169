.navigation-ui {
  width: 100%;
  max-width: 200px;
  box-shadow:
    0px 15px 125px rgb(201 180 250 / 13%),
    0px 4.52206px 37.6838px rgb(201 180 250 / 8%),
    0px 1.87823px 15.6519px rgb(201 180 250 / 7%),
    0px 0.67932px 5.661px rgb(201 180 250 / 5%);
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0;
  z-index: 1;
  background: #fff;

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    transition: $transition;

    @media screen and (max-width: $md) {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__item {
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #e4e4e5;

    @media screen and (max-width: $md) {
      padding: 5px 10px;
      border: none;
    }
  }

  &__link {
    @include font-20-bold;
    color: var(--text-light);
    transition: $transition;

    &.active,
    &:hover {
      color: var(--accent-color);
    }

    @media screen and (max-width: $md) {
      @include font-12-bold;
    }
  }

  &__submenu {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-top: 10px;

    &-item {
      @include font-14-bold;
      display: flex;
      align-items: center;
      padding-bottom: 7px;
      color: var(--text-light);
      transition: $transition;

      &.active,
      &:hover {
        color: var(--accent-color);
      }

      &:before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--accent-color);
        margin-right: 10px;
      }

      &:last-child {
        padding-bottom: 0;
      }

      @media screen and (max-width: $md) {
        @include font-12-bold;

        &:before {
          width: 3px;
          height: 3px;
          margin-right: 3px;
        }
      }
    }

    @media screen and (max-width: $md) {
      padding-left: 5px;
      padding-top: 5px;
    }
  }

  &__btn {
    display: none;
    margin: 5px auto;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;

    @media screen and (max-width: $md) {
      display: flex;
    }
  }

  @media screen and (max-width: $md) {
    max-width: 50px;
    overflow: hidden;
    background: transparent;
    box-shadow:
      0px 15px 125px rgb(201 180 250 / 0%),
      0px 4.52206px 37.6838px rgb(201 180 250 / 0%),
      0px 1.87823px 15.6519px rgb(201 180 250 / 0%),
      0px 0.67932px 5.661px rgb(201 180 250 / 0%);

    &.active {
      max-width: 100px;
      box-shadow:
        0px 15px 125px rgb(201 180 250 / 13%),
        0px 4.52206px 37.6838px rgb(201 180 250 / 8%),
        0px 1.87823px 15.6519px rgb(201 180 250 / 7%),
        0px 0.67932px 5.661px rgb(201 180 250 / 5%);
      background-color: $white;
      overflow-y: auto;

      .navigation-ui__list {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
