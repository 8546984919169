@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
}

.price-block {
  position: absolute;
  top: -43px;
  right: 24px;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 36px;
  background-color: $white;
  padding: 32px 24px;
  width: 354px;

  .price {
    margin-bottom: 20px;

    .text {
      @include font-14;
    }

    .cost {
      @include font-32-bold;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    &_addToCartBtn {
      width: 100%;
    }

    .button {
      height: 48px;
      width: 100%;

      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }

    .favorite {
      height: 100%;
      width: 48px;

      svg {
        fill: var(--accent-color);
      }

      @media screen and (max-width: 1024px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.tag {
  background: var(--accent-light);
  border-radius: 36px;
  padding: 9px 12px 7px 12px;
  width: fit-content;
  color: var(--accent-color);
  margin-bottom: 16px;
  @include font-12-bold;
}

.title {
  margin-bottom: 24px;
  @include font-32-bold;

  @media (max-width: 1024px) {
    @include font-22-bold;
  }
}

.short-description {
  margin-bottom: 39px;
  @include font-14;
}

.payment-info {
  display: flex;
  gap: 20px;
  width: fit-content;
  margin-bottom: 48px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  .block {
    display: flex;
    gap: 16px;

    .icon {
      width: 48px;
      height: 48px;
      background: var(--accent-light);
      border-radius: 360px;
      padding: 12px;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--accent-color);
      }

      &.timer {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .info {
      min-width: 245px;

      @media (max-width: 1024px) {
        min-width: unset;
      }

      &-title {
        @include font-14;
      }

      &-text {
        text-transform: lowercase;
        @include font-18-bold;
      }
    }
  }
}

.description {
  margin-bottom: 32px;

  &-title {
    margin-bottom: 4px;
    color: var(--text-light);
    @include font-14;
  }

  &-text {
    @include font-14;

    p {
      margin: 0;
    }

    ul {
      margin-top: 12px;

      li {
        display: block;
        margin-bottom: 12px;

        &:before {
          content: '';
          display: inline-block;
          background-image: var(--check-icon);
          width: 20px;
          height: 20px;
          margin-right: 16px;
          position: relative;
          flex-shrink: 0;
          top: 2px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      & + p {
        margin-top: 12px;
      }
    }

    ol {
      counter-reset: counter;
      margin-top: 12px;

      li {
        display: flex;
        margin-bottom: 24px;
        counter-increment: counter;

        &:before {
          content: counter(counter) '.';
          background: linear-gradient(
            7.34deg,
            #8a45d4 -6.59%,
            #4393ed 63.47%,
            #63b6e8 143.98%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-right: 16px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      & + p {
        margin-top: 12px;
      }
    }

    @media screen and (max-width: 1024px) {
      @include font-14;
    }
  }
}

.mobile-price-block {
  background-color: $white;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #f2f4f7;

  .price {
    margin-bottom: 20px;

    .text {
      @include font-14;
    }

    .cost {
      @include font-32-bold;

      &--small {
        @include font-22-bold;

        @media screen and (max-width: 1024px) {
          @include font-20-bold;
        }
      }
    }

    .discount {
      @include font-20-bold;
      color: var(--text-light);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 8px;
        width: 100%;
        border-bottom: 2px solid var(--text-light);
        transform-origin: left bottom;
        transform: rotate(-8deg);
      }

      &--small {
        @include font-14-bold;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    &_addToCartBtn {
      width: 100%;
    }

    .button {
      height: 48px;
      width: 100%;

      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }

    .favorite {
      height: 100%;
      width: 48px;

      svg {
        fill: var(--accent-color);
      }

      @media screen and (max-width: 1024px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.priceDesciption {
  color: #898989;
  @include font-12;
  border-top: 1px solid #f2f4f7;
  padding-top: 12px;
  margin-top: 24px;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    @include font-11;
  }
}

.tags {
  display: flex;
  gap: 8px;

  .tagged {
    color: #8362f3;
    padding: 8px 12px 8px;
    background-color: #f7f5ff;
    border-radius: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .free {
    background-color: var(--green-color);
    color: $white;
  }

  .new {
    background-color: var(--ui-color-3);
    color: $white;
  }

  .hit-tag {
    background-color: $orange;
    color: $white;
    display: flex;
    gap: 4px;
  }
}

.infoName {
  &:after {
    content: ', ';
  }

  &:last-child:after {
    content: none;
  }
}
.modal {
  position: relative;
  display: flex;
  min-height: 356px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .left {
    padding: 40px;
    width: 472px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }

    .title {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    .text {
      margin-bottom: 32px;
      @include font-14;
    }

    .label {
      margin-bottom: 8px;
      margin-top: 32px;
      @include font-12-bold;
    }

    .add {
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .sources {
      max-height: 212px;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 1024px) {
        max-height: none;
      }

      .source {
        input {
          height: 40px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .source-wrapper {
        display: flex;
        width: 100%;
        gap: 8px;

        .source {
          width: 344px;

          @media screen and (max-width: 768px) {
            max-width: 344px;
            width: auto;
          }
        }

        .delete {
          width: 40px;
          height: 40px;
          border: 1px solid #e4e4e5;
          border-radius: 8px;
          padding: 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    height: 40px;
    gap: 8px;

    &_addToCartBtn {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      height: auto;
      margin-top: 24px;
    }

    .button {
      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }
  }

  .right {
    position: relative;
    width: 288px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .bg-image {
      object-fit: cover;
      /* height: 410px; */
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .price-count {
      position: absolute;
      top: 25%;
      left: 32px;
      box-shadow:
        0px 15px 125px rgba(201, 180, 250, 0.13),
        0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
        0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
        0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
      border-radius: 16px;
      background-color: $white;
      padding: 17px 16px;
      width: 224px;

      @media screen and (max-width: 1024px) {
        position: relative;
        background-color: var(--accent-light);
        box-shadow: none;
        width: 100%;
        left: auto;
      }

      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        @include font-12;

        span {
          @include font-14-bold;
        }
      }

      .total {
        margin-top: 13px;
        padding-top: 12px;
        border-top: 1px solid rgba(var(--text-light), 0.3);
        @include font-14;

        span {
          @include font-22-bold;
        }
      }
    }

    .hint {
      color: var(--text-light);
      margin-top: 12px;
      @include font-12;
    }
  }
}

.deals {
  display: flex;
  align-items: center;
  background: var(--gradient-3);
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 36px 36px 0px 0px;
  padding: 16px 24px;
  z-index: 1;
  position: relative;
  justify-content: space-between;
  background-image: var(--gradient-3);
  background-size: cover;
  margin-bottom: -20px;

  @media screen and (max-width: $xs-up) {
    border-radius: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
}

.deal {
  text-shadow:
    0px 33px 100px rgba(65, 25, 111, 0.0201946),
    0px 7.37098px 22.3363px rgba(65, 25, 111, 0.0298054),
    2px 1px 2px rgba(32, 13, 55, 0.06);
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: $white;
  position: relative;
  top: 3px;
  border-radius: 32px 32px 0 0;

  @media screen and (max-width: $xs-up) {
    font-size: 20px;
    line-height: 32px;
  }
}

.priceDeals {
  padding-top: 64px;
}

.in-cart {
  border: 1px solid var(--accent-color);
  background-color: inherit;
  color: var(--accent-color);
  display: flex;

  span {
    margin-left: 8px;
    top: 0;
    background: var(--accent-color);
    border-radius: 50%;
    color: $white;
    width: auto;
    min-width: 16px;
    height: 16px;
    text-align: center;
    padding: 1px 4px 0 4px;
    @include font-12-bold;
  }
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px 4px 0;
  margin: -3px 0 0 8px;
  border-radius: 50%;
  @include font-12-bold;
  background: var(--accent-color);
  color: $white;
}

.counterMedium {
  width: 20px;
  height: 20px;
}

.disclaimer {
  color: var(--accent-color);
  cursor: pointer;
}
