@import 'styles/helpers/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: sticky;
  top: 16px;

  @media screen and (max-width: $xs-up) {
    position: static;
    top: auto;
    margin-top: $space-xl;
  }
}

.wrapper {
  background: #ffffff;
  box-shadow: var(--cart-shadow);
  border-radius: 24px;
  padding: 12px;

  @media screen and (max-width: $xs-up) {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
}

.wrapperLogout {
  background: #ffffff;
  box-shadow: var(--cart-shadow);
  border-radius: 24px;
  padding: 12px;
}

.list {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $xs-up) {
    background: #ffffff;
    box-shadow: var(--cart-shadow);
    border-radius: 24px;
    padding: 12px;
  }
}

.logout {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 40px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--accent-light);
  }

  @media screen and (max-width: $xs-up) {
    border-radius: 40px;
    padding: 10px 16px;
  }
}

.back {
  order: 3;
  transform: rotate(-90deg);
  display: none;

  @media screen and (max-width: $xs-up) {
    display: flex;
    pointer-events: none;
    margin-left: auto;
  }
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  padding: 8px 16px;
  border-radius: 40px;
  transition: background-color 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: var(--accent-light);
  }

  @media screen and (max-width: $xs-up) {
    margin-bottom: 8px;
    border-radius: 40px;
    padding: 14px 22px;

    &.active {
      position: absolute;
      top: 0;
      z-index: 40;
      width: 100%;
      margin-top: 55px;
      padding-top: 16px;
      padding-bottom: 16px;
      transform: translateY(-80%);

      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background-color: var(--accent-color);
        border-radius: 50%;
        position: absolute;
        left: -12px;
      }

      .icon {
        display: none;
      }

      .back {
        display: block;
        transform-origin: center;
        transform: rotate(90deg);
        position: relative;
        left: -12px;
        order: 1;
        margin: 0;
        top: 1px;
        pointer-events: all;
      }

      .name {
        order: 2;
      }
    }
  }

  &.active {
    padding: 8px 16px;
    border-radius: 40px;
    background-color: var(--accent-light);

    .name {
      @include font-14-bold;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.name {
  color: var(--text-color);
  @include font-14;
}

.mobileTitle {
  @include font-24-bold;
  margin-bottom: 16px;

  @media (max-width: $sm) {
    @include font-22-bold;
  }
}

.chosen {
  .mobileTitle {
    display: none;
  }

  @media screen and (max-width: $xs-up) {
    .link:not(.active) {
      display: none;
    }
  }
}

.link-back {
  display: flex;
  gap: 6px;
  @include font-12-bold;
  margin-bottom: 24px;
  color: var(--accent-color);

  &__arrow {
    width: 16px;
    height: 16px;
    transform: scale(0.8);

    path {
      fill: var(--accent-color);
    }
  }
}

.isHidden {
  @media screen and (max-width: $xs-up) {
    display: none;
  }
}

.backTo {
  color: var(--accent-color);
}

.link-back__arrow path {
  fill: var(--accent-color);
}

.inner {
  max-width: 100%;
  padding: 40px;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $xs-up) {
    max-width: 480px;
    height: unset;
    flex-direction: column;
    gap: 24px;
  }

  .header {
    .title {
      @include font-22-bold;
      margin: 0;
      margin-bottom: 8px;
      color: var(--text-color);

      @media screen and (max-width: $xs) {
        @include font-18-bold;
      }
    }

    .title-description {
      @include font-14;
      color: var(--text-light);
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-12;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media screen and (max-width: $xs) {
      flex-direction: column;
    }

    .btn {
      width: 100%;
    }
  }
}
