@import 'styles/helpers/variables.scss';

.wrapper {
  margin-top: 40px;
  border-radius: 36px;
  background: $white;
  box-shadow:
    0px 4.522px 37.684px 0px rgba(201, 180, 250, 0.12),
    0px 15px 125px 0px rgba(201, 180, 250, 0.13);
  padding: 24px 24px 40px 24px;
}

.table {
  color: var(--text-color);
  &__row {
    display: grid;
    grid-template-columns: 30px 380px 260px 258px 160px 160px;
    border-bottom: 1px solid #f7f5ff;

    @media (max-width: 1350px) {
      grid-template-columns: 30px 2fr 2fr 1fr 1fr 1fr;
    }

    &:last-child {
      border-bottom: 0;
    }

    &_header {
      @include font-12-bold;
      overflow: hidden;
    }

    &:first-child {
      .table__cell {
        &:first-child {
          font-weight: 400;
        }
      }
    }
  }

  &__cell {
    padding: 12px 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: var(--text-color);

    &:first-child {
      padding: 12px 8px;
      color: var(--holder-color);
    }
  }
}

.text-cell {
  display: inline-block;
  @include font-12;
  color: var(--text-color);
  width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;

  a {
    color: var(--text-color);
    &:hover {
      color: var(--accent-color);
    }
  }
}

.link-cell {
  @include font-12;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  color: var(--accent-color);
  a {
    color: var(--accent-color);
  }
}

.button {
  padding: 20px 0;
  background: none;
}
