@import 'styles/helpers/variables.scss';

.kpiContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  .errorText {
    color: #ea4a70;
  }

  .addButton {
    display: flex;
    color: var(--accent-color);
    @include font-14-bold;
    cursor: pointer;
    align-items: center;
    width: fit-content;

    &__error {
      color: #ea4a70;

      .plusIcon {
        svg {
          stroke: #ea4a70 !important;
        }
      }
    }

    .plusIcon {
      display: flex;

      svg {
        stroke: var(--accent-color);
        stroke-width: 1px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .kpiStep {
    .kpiStepTitle {
      color: #9c93aa;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;
      justify-content: space-between;

      .deleteButton {
        color: var(--accent-color);
        @include font-12-bold;
        background: transparent;
        border: none;
      }

      &__titleContainer {
        display: flex;
        gap: 4px;

        .infoIcon {
          display: flex;
          align-items: center;
        }

        .infoTooltip {
          @media screen and (max-width: 1024px) {
            :global(.common__tooltip-inner) {
              transform: translateX(60%);

              &::after {
                right: 90px;
              }
            }
          }
        }
      }

      &__text {
        display: flex;
        align-items: center;
        gap: 4px;
        @include font-14-bold;
        color: var(--text-light);
      }

      .counter {
        font-weight: 400;
        @include font-12;
        transform: translateY(2px);
        color: var(--text-light);
      }
    }

    .kpiItems {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      &__input {
        width: 50%;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .inputGroup {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: nowrap;

        @media (max-width: 1024px) {
          flex-direction: column;
        }

        &__item {
          width: 100%;
          @media screen and (min-width: 1024px) {
            width: 127px;
          }
        }
      }
    }
  }
}
