@import '/src/styles/helpers/variables.scss';

.user {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    position: relative;
    top: -1px;
  }
}

.wrap {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 3px;
  }
}

.name {
  @include font-14;
  padding-left: 8px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
  color: var(--text-color);
  height: 15px;
  position: relative;
  top: 1px;

  @media screen and (max-width: 1024px) {
    display: block;
    font-size: 11px;
    padding-left: 0;
    max-width: 100%;
  }
}
