@import "styles/helpers/variables.scss";

.wrapper {
  padding: 40px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 50px 0;
  }
}

.breadcrumbs {
  display: flex;
  margin-bottom: 24px;
  @include font-14;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 6px;

    &::after {
      content: '';
      width: 3px;
      height: 3px;
      margin-left: 6px;
      background: var(--text-color);
      border-radius: 50%;
    }

    &:last-child {
      .breadcrumbs__link {
        color: var(--holder-color);
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        display: none;
      }
    }
  }

  &__link {
    color: var(--text-color);
  }
}

.content-wrapper {
  margin-top: 24px;
  border-radius: 36px;
  background: $white;
  box-shadow: var(--top-shadow);
  padding: 24px;

  @media screen and (max-width: 680px) {
    margin: 24px -20px 0px -20px;
    padding: 0;
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__period {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--text-color);

    @media screen and (max-width: $xs) {
      padding-left: 20px;
      border-radius: 20px;
    }

    button {
      text-transform: lowercase;
      .arrow {
        margin-left: 2px;
        width: 16px;
        height: 16px;
        transform: translateY(7px);
        path {
          fill: var(--accent-color);
        }

        &--active {
          transform: translateY(-4px) translateX(-4px) rotate(180deg);
        }
      }
    }
  }

  &__select {
    max-width: max-content;

    span {
      text-transform: lowercase;
      font-weight: 500 !important;
      color: var(--accent-color);
    }

    svg {
      path {
        fill: var(--accent-color);
      }
    }
  }
}

.alert {
  display: flex;
  padding: 24px;
  gap: 16px;
  border-radius: 12px;
  background-color: var(--accent-light);
  max-width: 748px;
  width: 100%;
  margin-bottom: 24px;

  &__message {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_title {
      @include font-16-bold;
      color: var(--text-color);
    }

    &_text {
      padding: 0;
      margin: 0;
      @include font-12;
      color: var(--text-color);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;

    &__message {
      &_title {
        @include font-14-bold;
      }
    }
  }
}

.modal {
  button {
    padding: 12px 0px !important;
  }
}

.mainButton {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 38px;
  padding: 8px 24px 8px 20px;
  @include font-16-bold;

  &__icon {
    svg {
      display: flex;
      align-items: center;
    }
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;

  &__top--center {
    left: 50% !important;
  }
}

.hints-block {
  box-sizing: border-box;
  &__shrink {
    width: max-content;
    max-width: 100%;
  }
}

.noticebox {
  margin-bottom: 24px;
  max-width: 748px;
}

.customGoalsTag {
  @media (max-width: 768px) {
    padding: 8px 22px; 
  }
}