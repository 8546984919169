@import 'styles/helpers/variables.scss';

.card {
  height: auto;
  width: 100%;
  max-width: 405px;
  padding: 16px;
  border-radius: 20px;
  background: var(--accent-light);
  color: var(--text-color);
  @include font-12;

  &__title {
    color: var(--text-light);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    button {
      font-weight: 400;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 24px auto;

    @media screen and (max-width: 768px) {
      margin: 12px auto 0px;
    }

    span {
      color: var(--text-light);
    }
  }

  &__more {
    display: flex;
    align-items: center;
    gap: 4px;
    @include font-12-bold;

    svg {
      transform: translateY(4px);
      path {
        fill: var(--accent-color);
      }
    }

    &--active {
      svg {
        transform: rotate(180deg) translateY(1.5px);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: max-content;
    max-width: unset;
  }
}

.modal {
  padding: 40px;
  width: 480px;
  max-height: 420px;

  &__title {
    display: inline-block;
    color: var(--text-color);
    margin-bottom: 16px;
    @include font-24-bold;
  }

  &__documents {
    padding-right: 8px;
    max-height: 290px;
    overflow: hidden;
    gap: 8px !important;

    &:hover {
      overflow-y: auto;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-third);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-second);
      border-radius: 8px;
    }

    li {
      div {
        @include font-16;
      }
      span {
        @include font-14;
      }
    }
  }
}
