@import 'styles/helpers/variables.scss';

.toast-container {
  position: relative;
  align-items: center;
  z-index: 9999;

  &_new {
    border: none;
    width: 480px;

    @media screen and (max-width: $xs-up) {
      width: 100%;
    }

    .success {
      border: none !important;
      align-items: flex-start;
      padding: 16px 40px 16px 16px !important;
      background: $success-notification;
      backdrop-filter: blur(4px);
      border-radius: 24px;
      color: var(--text-color);

      @media screen and (max-width: $xs-up) {
        padding-right: 16px;
      }

      .titleText {
        @include font-14-bold;
        font-weight: var(--font-weight-bold);
        font-family: $font-text;
      }
    }

    .icon {
      margin-right: 12px;
      display: flex;
      border-radius: 32px;
      width: 28px;
      height: 28px;
      padding: 6px;
      background: #37B24D1F;
      margin-top: 0 !important;

      svg {
        width: unset;
      }
    }
  }
}

.toast {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;

  &.success {
    background: linear-gradient(
        0deg,
        rgba(0, 200, 83, 0.04),
        rgba(0, 200, 83, 0.04)
      ),
      #ffffff;
    border: 1px solid $green;
  }

  &.error {
    display: flex;
    align-items: flex-start;
    background: linear-gradient(
        0deg,
        rgba(245, 34, 45, 0.04),
        rgba(245, 34, 45, 0.04)
      ),
      #ffffff;
    border: 1px solid #f5222d;
  }
}

.icon {
  margin-right: 12px;
  display: flex;
  width: 14px;
  height: 14px;
  margin-top: 6px;

  svg {
    width: 14px;
  }
}

.text {
  position: relative;
  top: 2px;

  font-family: $font-text;
  @include font-12;
}
