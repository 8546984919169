//Color

.rulocale {
  font-family: 'Raleway', Helvetica, sans-serif;
  font-variant-numeric: lining-nums proportional-nums;
  --font-fam: 'Raleway', Helvetica, sans-serif;
  --font-cur: 'Raleway', Helvetica, sans-serif;
  --accent-color: #f14b4b;
  --main-rgb: 241, 75, 75;
  --accent-light: #fff3f3;
  --accent-light-disabled: #fff3f380;
  --accent-second: #ffc8c8;
  --accent-third: #ffeaea;
  --light-gray: #fff3f3;
  --gray-color: #d3c4c4;
  --gray-second: #eddddd;
  --text-color: #363232;
  --text-light: #9c8e8b;
  --text-grey: #898989;
  --ui-color-3: #bc6ef3;
  --ui-3-rgb: 188, 110, 243;
  --ui-01-8: rgba(255, 185, 82, 0.08);
  --ui-02-8: rgba(136, 208, 85, 0.08);
  --ui-03-8: rgba(188, 110, 243, 0.08);
  --ui-04-8: rgba(110, 133, 248, 0.08);
  --error-color: #da2626;
  --base-white: #ffffff;
  --error-8: rgba(218, 38, 38, 0.08);

  --base-gradient: linear-gradient(138deg, #ffb3d2 -52.18%, #ff4848 62.86%);
  --text-gradien: linear-gradient(150deg, #e47267 8.91%, #eb5945 93.43%);
  --start-gradient: linear-gradient(150.02deg, #e47267 8.91%, #eb5945 93.43%);
  --hotdeal-gradient: linear-gradient(150.02deg, #e47267 8.91%, #eb5945 93.43%);
  --hotdeal-back: var(--accent-color);
  --notice-default-gradient: linear-gradient(
    128deg,
    rgba(110, 133, 248, 0.4) 2.75%,
    #6e85f8 93.39%
  );
  --notice-warning-gradient: linear-gradient(
    128deg,
    rgba(188, 110, 243, 0.4) 2.75%,
    #bc6ef3 93.39%
  );
  --notice-waiting-gradient: linear-gradient(
    145deg,
    rgba(255, 185, 82, 0.4) 10.71%,
    #ffb952 91.87%
  );
  --preview-box-shadow: 0 4.52px 37.68px 0 #e582971b, 0 15px 77px 0 #e582971a;

  --green-color: #88d055;
  --holder-color: #9c8b8b;
  --divider-color: #9c8b8b;

  --card-shadow: 0px 15px 85px 0px rgba(229, 130, 151, 0.21),
    0px 4.522px 61.68px 0px rgba(229, 130, 151, 0.19);
  --card-shadow-hover: 0px 15px 125px 0px rgba(228, 115, 104, 0.33);
  --cart-shadow: 0px 15px 125px 0px rgba(228, 115, 104, 0.13);
  --top-shadow: 0px 15px 125px 0px rgba(250, 180, 180, 0.13),
    0px 4.522px 37.684px 0px rgba(250, 201, 180, 0.08),
    0px 1.878px 15.652px 0px rgba(250, 201, 180, 0.06),
    0px 0.679px 5.661px 0px rgba(250, 201, 180, 0.05);
  --red-shadow: 0px 15px 53px 0px #e4686854;
  --select-box-shadow: 0px 4.52px 37.68px 0px #e582971b,
    0px 15px 77px 0px #e582971a;
  --goals-block-shadow: 0px 1.88px 15.65px 0px #fab4b511,
    0px 4.52px 37.68px 0px #fab4b516;
  --line-dotted: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgNyAyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxSDYiIHN0cm9rZT0iI0ZGRUFFQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtZGFzaGFycmF5PSIyIDIiLz4KPC9zdmc+Cg==');
  --check-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTQuNjA2NCA4LjY4OTk2SDQuNjA2MzRIMy40NjQyM0MzLjMxMDYgOC42ODk5NiAzLjIzMTA5IDguODY5MiAzLjMyMTU5IDguOTg3NDFMMy4zMjE2MSA4Ljk4NzQzTDcuNzk2OTIgMTQuODI2TDcuNzk2OTQgMTQuODI2QzguMDI2MTcgMTUuMTI0OCA4LjQ2NzMxIDE1LjEyNDUgOC42OTgwMiAxNC44MjYyTDguNjk4MTUgMTQuODI2TDE2LjY3OCA0LjQxMjgzQzE2LjY3OCA0LjQxMjc2IDE2LjY3ODEgNC40MTI2OSAxNi42NzgxIDQuNDEyNjJDMTYuNzY5NCA0LjI5NTM3IDE2LjY4ODQgNC4xMTY2OSAxNi41MzU2IDQuMTE2NjlIMTUuMzkzNUMxNS4yMTc2IDQuMTE2NjkgMTUuMDUxIDQuMTk5ODMgMTQuOTQzNiA0LjM0MTdDMTQuOTQzNiA0LjM0MTczIDE0Ljk0MzUgNC4zNDE3NiAxNC45NDM1IDQuMzQxNzlMOC4yNDY3MiAxMy4wNzc5TDUuMDU2MTcgOC45MTQ4NkM1LjAwMjc1IDguODQ1MDEgNC45MzQ1NiA4Ljc4ODQxIDQuODU2NjUgOC43NDk0MkM0Ljc3ODc0IDguNzEwNDIgNC42OTMxNyA4LjY5MDA3IDQuNjA2NCA4LjY4OTk2WiIKICAgICAgICBmaWxsPSIjRTQ3MjY3IiBzdHJva2U9IiNFNDcyNjciIHN0cm9rZS13aWR0aD0iMC4xIiAvPgo8L3N2Zz4=');
  --check-circle-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iOTciIHZpZXdCb3g9IjAgMCA5NiA5NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDgiIGN5PSI0OC41IiByPSI0NC42ODk3IiBzdHJva2U9InVybCgjcGFpbnQwX2xpbmVhcl82XzU3MjczKSIgc3Ryb2tlLXdpZHRoPSI2LjYyMDY5Ii8+CjxwYXRoIGQ9Ik0yOC45NjUzIDQ4LjQ3MUw0Mi4xODg3IDYyLjA4NjJMNjkuNTE3MSAzMy45NDgyIiBzdHJva2U9InVybCgjcGFpbnQxX2xpbmVhcl82XzU3MjczKSIgc3Ryb2tlLXdpZHRoPSI2LjYyMDY5IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl82XzU3MjczIiB4MT0iLTQ2LjU2IiB5MT0iLTUyLjc4IiB4Mj0iNTguMDgiIHkyPSI2Mi45IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiNGRkIzRDIiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRkY0ODQ4Ii8+CjwvbGluZWFyR3JhZGllbnQ+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQxX2xpbmVhcl82XzU3MjczIiB4MT0iOS4yOTc3NCIgeTE9IjE4LjMzMTciIHgyPSIzNy4wNTY3IiB5Mj0iNjIuNTU4MSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkZCM0QyIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGNDg0OCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
  --arrow-icon: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTkuOTk5OTIgMy40NzMxNlY0Ljc4ODk3QzkuOTk5OTIgNC44Nzg0NSAxMC4wMzI1IDQuOTYyNjYgMTAuMDg1OSA1LjAxNTI5TDE0LjUwMzIgOS4zMzMyNUwxNC41MDI1IDEwLjY2NjZMMTAuMDg1OSAxNC45ODM5QzEwLjAzMjUgMTUuMDM2NSA5Ljk5OTkyIDE1LjEyMDcgOS45OTk5MiAxNS4yMTAyVjE2LjUyNkM5Ljk5OTkyIDE2LjY0IDEwLjA5NjMgMTYuNzA2NyAxMC4xNjUzIDE2LjY0TDE2LjQ5NDcgMTAuNDU0QzE2LjcyMzkgMTAuMjI5NCAxNi43MjM5IDkuNzY5NzYgMTYuNDk0NyA5LjU0Njk1TDEwLjE2NTMgMy4zNjA4OEMxMC4wOTYzIDMuMjkyNDYgOS45OTk5MiAzLjM1OTEzIDkuOTk5OTIgMy40NzMxNloiCiAgICAgICAgZmlsbD0iI0U0NzI2NyIgLz4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE0LjUwMzIgOS4zMzMyNUgzLjY2NjU4QzMuNDgyNDkgOS4zMzMyNSAzLjMzMzI1IDkuNDgyNDkgMy4zMzMyNSA5LjY2NjU5VjEwLjMzMzNDMy4zMzMyNSAxMC41MTczIDMuNDgyNDkgMTAuNjY2NiAzLjY2NjU5IDEwLjY2NjZIMTQuNTAyNUwxNC41MDMyIDkuMzMzMjVaIgogICAgICAgIGZpbGw9IiNFNDcyNjciIC8+Cjwvc3ZnPg==');
  --arrow-white-icon: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTkuOTk5OTIgMy40NzMxNlY0Ljc4ODk3QzkuOTk5OTIgNC44Nzg0NSAxMC4wMzI1IDQuOTYyNjYgMTAuMDg1OSA1LjAxNTI5TDE0LjUwMzIgOS4zMzMyNUwxNC41MDI1IDEwLjY2NjZMMTAuMDg1OSAxNC45ODM5QzEwLjAzMjUgMTUuMDM2NSA5Ljk5OTkyIDE1LjEyMDcgOS45OTk5MiAxNS4yMTAyVjE2LjUyNkM5Ljk5OTkyIDE2LjY0IDEwLjA5NjMgMTYuNzA2NyAxMC4xNjUzIDE2LjY0TDE2LjQ5NDcgMTAuNDU0QzE2LjcyMzkgMTAuMjI5NCAxNi43MjM5IDkuNzY5NzYgMTYuNDk0NyA5LjU0Njk1TDEwLjE2NTMgMy4zNjA4OEMxMC4wOTYzIDMuMjkyNDYgOS45OTk5MiAzLjM1OTEzIDkuOTk5OTIgMy40NzMxNloiCiAgICAgICAgZmlsbD0iI0ZGRkZGRiIgLz4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE0LjUwMzIgOS4zMzMyNUgzLjY2NjU4QzMuNDgyNDkgOS4zMzMyNSAzLjMzMzI1IDkuNDgyNDkgMy4zMzMyNSA5LjY2NjU5VjEwLjMzMzNDMy4zMzMyNSAxMC41MTczIDMuNDgyNDkgMTAuNjY2NiAzLjY2NjU5IDEwLjY2NjZIMTQuNTAyNUwxNC41MDMyIDkuMzMzMjVaIgogICAgICAgIGZpbGw9IiNGRkZGRkYiIC8+Cjwvc3ZnPg==');
  --dotted-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23F14B4B' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --projects-shadow: linear-gradient(
    359deg,
    #fff3f0 0.82%,
    rgba(255, 243, 240, 0) 114.25%
  );
  --small-shadow: 0px 2.52px 21.68px 0px #e5828e1b, 0px 5px 21px 0px #e582971c;
  --shadow-default: 0px 4.52px 37.68px rgba(229, 130, 151, 0.1),
  0px 15px 77px rgba(229, 130, 151, 0.1);

  --error-bg: #dc252514;
  --error-border-hover: #dc252566;
  --switcher-active-bg: linear-gradient(
    138deg,
    #ffb3d2 -52.18%,
    #ff4848 62.86%
  );
  --empty-bg-gradient: radial-gradient(
    105.14% 59.91% at 97.78% 4.93%,
    #ffd8d8 0%,
    #ffd8d8 6.67%,
    #ffd9d9 13.33%,
    #ffdbdb 20%,
    #ffdede 26.67%,
    #ffe1e1 33.33%,
    #ffe5e5 40%,
    #ffe9e9 46.67%,
    #fee 53.33%,
    #fff2f2 60%,
    #fff6f6 66.67%,
    #fff9f9 73.33%,
    #fffcfc 80%,
    #fffefe 86.67%,
    #fff 93.33%,
    #fff 100%
  );
  --empty-bg-gradient-mobile: radial-gradient(
    202.93% 73.18% at 100% 0%,
    #ffd8d8 0%,
    #ffd8d8 6.67%,
    #ffd9d9 13.33%,
    #ffdbdb 20%,
    #ffdede 26.67%,
    #ffe1e1 33.33%,
    #ffe5e5 40%,
    #ffe9e9 46.67%,
    #fee 53.33%,
    #fff2f2 60%,
    #fff6f6 66.67%,
    #fff9f9 73.33%,
    #fffcfc 80%,
    #fffefe 86.67%,
    #fff 93.33%,
    #fff 100%
  );
  --font-weight-bold: 700;

  --error-page-bg: url('/assets/img/System/gradientRu.png');
  --error-page-bg-mob: url('/assets/img/System/gradientRu-mobile.png');
  --error-page-notAuth-bg: url('/assets/img/System/gradientNotAuthRu.png');
  --error-page-notAuth-bg-mob: url('/assets/img/System/gradientNotAuthRu-mobile.png');
}

.enlocale {
  font-family: 'Grato Grotesk', Helvetica, sans-serif;
  --font-fam: 'Grato Grotesk', Helvetica, sans-serif;
  --font-cur: 'Noto', Helvetica, sans-serif;
  --accent-color: #8362f3;
  --main-rgb: 131, 98, 243;
  --accent-light: #f7f5ff;
  --accent-light-disabled: #f7f5ff80;
  --accent-second: #ddc8ff;
  --accent-third: #f2ecff;
  --light-gray: #f2f4f7;
  --gray-color: #c4bbd2;
  --gray-second: #d8cfe6;
  --text-color: #323236;
  --text-light: #9c93aa;
  --text-grey: #898989;
  --ui-color-3: #d96af5;
  --ui-3-rgb: 217, 106, 245;
  --ui-01-8: rgba(246, 125, 108, 0.08);
  --ui-02-8: rgba(90, 210, 138, 0.08);
  --ui-03-8: rgba(217, 106, 245, 0.08);
  --ui-04-8: rgba(108, 147, 253, 0.08);
  --error-color: #ea4a70;
  --base-white: #ffffff;
  --error-8: rgba(234, 74, 112, 0.08);

  --base-gradient: linear-gradient(135.83deg, #6e3cff -26.22%, #a54bff 116.77%);
  --text-gradien: linear-gradient(140deg, #6e3cff -15.56%, #4ba5ff 109.74%);
  --start-gradient: linear-gradient(128deg, #4ba5ff -4.96%, #a54bff 109.83%);
  --hotdeal-gradient: linear-gradient(
    0deg,
    rgba(131, 98, 243, 0.43),
    rgba(131, 98, 243, 0.43)
  );
  --hotdeal-back: url('/assets/img/HotDeals/back.png');
  --notice-default-gradient: linear-gradient(
    145deg,
    rgba(108, 147, 253, 0.4) 10.71%,
    #6c93fd 91.87%
  );
  --notice-warning-gradient: linear-gradient(
    128deg,
    rgba(217, 106, 245, 0.4) 2.75%,
    #d96af5 93.39%
  );
  --notice-waiting-gradient: linear-gradient(
    128deg,
    rgba(246, 125, 108, 0.4) 2.75%,
    #f67d6c 93.39%
  );
  --preview-box-shadow: 0 4.52px 37.68px 0 #c9b4fa20, 0 15px 125px 0 #c9b4fa21;

  --green-color: #46d387;
  --holder-color: #9c93aa;
  --divider-color: #a09cac;
  --card-shadow: 0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  --card-shadow-hover: 0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 1px 32px rgba(28, 20, 37, 0.12);
  --cart-shadow: 0 4.52px 37.68px 0 #c9b4fa20, 0 15px 125px 0 #c9b4fa21;
  --top-shadow: 0px 15px 125px rgba(201, 180, 250, 0.13),
    0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
    0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
    0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
  --select-box-shadow: 0px 4.52px 37.68px 0px #d7b4fa20,
    0px 15px 125px 0px #c6b4fa21;
  --goals-block-shadow: 0px 0.68px 5.66px 0px #c9b4fa0c,
    0px 1.88px 15.65px 0px #c9b4fa11, 0px 4.52px 37.68px 0px #c9b4fa16;
  --line-dotted: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjIiIHZpZXdCb3g9IjAgMCA4IDIiIGZpbGw9Im5vbmUiPgogICAgPHBhdGggZD0iTTEgMUgyNDAuNSIgc3Ryb2tlPSIjRERDOEZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjIgMiIgLz4KPC9zdmc+');
  --check-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTQuNjA2NCA4LjY4OTk2SDQuNjA2MzRIMy40NjQyM0MzLjMxMDYgOC42ODk5NiAzLjIzMTA5IDguODY5MiAzLjMyMTU5IDguOTg3NDFMMy4zMjE2MSA4Ljk4NzQzTDcuNzk2OTIgMTQuODI2TDcuNzk2OTQgMTQuODI2QzguMDI2MTcgMTUuMTI0OCA4LjQ2NzMxIDE1LjEyNDUgOC42OTgwMiAxNC44MjYyTDguNjk4MTUgMTQuODI2TDE2LjY3OCA0LjQxMjgzQzE2LjY3OCA0LjQxMjc2IDE2LjY3ODEgNC40MTI2OSAxNi42NzgxIDQuNDEyNjJDMTYuNzY5NCA0LjI5NTM3IDE2LjY4ODQgNC4xMTY2OSAxNi41MzU2IDQuMTE2NjlIMTUuMzkzNUMxNS4yMTc2IDQuMTE2NjkgMTUuMDUxIDQuMTk5ODMgMTQuOTQzNiA0LjM0MTdDMTQuOTQzNiA0LjM0MTczIDE0Ljk0MzUgNC4zNDE3NiAxNC45NDM1IDQuMzQxNzlMOC4yNDY3MiAxMy4wNzc5TDUuMDU2MTcgOC45MTQ4NkM1LjAwMjc1IDguODQ1MDEgNC45MzQ1NiA4Ljc4ODQxIDQuODU2NjUgOC43NDk0MkM0Ljc3ODc0IDguNzEwNDIgNC42OTMxNyA4LjY5MDA3IDQuNjA2NCA4LjY4OTk2WiIKICAgICAgICBmaWxsPSIjODM2MkYzIiBzdHJva2U9IiM4MzYyRjMiIHN0cm9rZS13aWR0aD0iMC4xIiAvPgo8L3N2Zz4=');
  --check-circle-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iOTYiIHZpZXdCb3g9IjAgMCA5NiA5NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDgiIGN5PSI0OCIgcj0iNDQuNjg5NyIgc3Ryb2tlPSJ1cmwoI3BhaW50MF9saW5lYXJfMzYxOF82OTE5MikiIHN0cm9rZS13aWR0aD0iNi42MjA2OSIvPgo8cGF0aCBkPSJNMjguOTY0OCA0NS45NzFMNDIuMTg4MiA1OS41ODYyTDY5LjUxNjYgMzEuNDQ4MiIgc3Ryb2tlPSJ1cmwoI3BhaW50MV9saW5lYXJfMzYxOF82OTE5MikiIHN0cm9rZS13aWR0aD0iNi42MjA2OSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzM2MThfNjkxOTIiIHgxPSI0OCIgeTE9IjAiIHgyPSIxNS4zMTAzIiB5Mj0iOTYiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzc2OTlFNyIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM4MzYyRjMiLz4KPC9saW5lYXJHcmFkaWVudD4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDFfbGluZWFyXzM2MThfNjkxOTIiIHgxPSI0OS4yNDA3IiB5MT0iMzEuNDQ4MiIgeDI9IjQyLjIxMzgiIHkyPSI2MS4xODg1IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM3Njk5RTciLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjODM2MkYzIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==');
  --arrow-icon: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTkuOTk5OTIgMy40NzMxNlY0Ljc4ODk3QzkuOTk5OTIgNC44Nzg0NSAxMC4wMzI1IDQuOTYyNjYgMTAuMDg1OSA1LjAxNTI5TDE0LjUwMzIgOS4zMzMyNUwxNC41MDI1IDEwLjY2NjZMMTAuMDg1OSAxNC45ODM5QzEwLjAzMjUgMTUuMDM2NSA5Ljk5OTkyIDE1LjEyMDcgOS45OTk5MiAxNS4yMTAyVjE2LjUyNkM5Ljk5OTkyIDE2LjY0IDEwLjA5NjMgMTYuNzA2NyAxMC4xNjUzIDE2LjY0TDE2LjQ5NDcgMTAuNDU0QzE2LjcyMzkgMTAuMjI5NCAxNi43MjM5IDkuNzY5NzYgMTYuNDk0NyA5LjU0Njk1TDEwLjE2NTMgMy4zNjA4OEMxMC4wOTYzIDMuMjkyNDYgOS45OTk5MiAzLjM1OTEzIDkuOTk5OTIgMy40NzMxNloiCiAgICAgICAgZmlsbD0iIzgzNjJGMyIgLz4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE0LjUwMzIgOS4zMzMyNUgzLjY2NjU4QzMuNDgyNDkgOS4zMzMyNSAzLjMzMzI1IDkuNDgyNDkgMy4zMzMyNSA5LjY2NjU5VjEwLjMzMzNDMy4zMzMyNSAxMC41MTczIDMuNDgyNDkgMTAuNjY2NiAzLjY2NjU5IDEwLjY2NjZIMTQuNTAyNUwxNC41MDMyIDkuMzMzMjVaIgogICAgICAgIGZpbGw9IiM4MzYyRjMiIC8+Cjwvc3ZnPg==');
  --arrow-white-icon: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIiBmaWxsPSJub25lIj4KICAgIDxwYXRoCiAgICAgICAgZD0iTTkuOTk5OTIgMy40NzMxNlY0Ljc4ODk3QzkuOTk5OTIgNC44Nzg0NSAxMC4wMzI1IDQuOTYyNjYgMTAuMDg1OSA1LjAxNTI5TDE0LjUwMzIgOS4zMzMyNUwxNC41MDI1IDEwLjY2NjZMMTAuMDg1OSAxNC45ODM5QzEwLjAzMjUgMTUuMDM2NSA5Ljk5OTkyIDE1LjEyMDcgOS45OTk5MiAxNS4yMTAyVjE2LjUyNkM5Ljk5OTkyIDE2LjY0IDEwLjA5NjMgMTYuNzA2NyAxMC4xNjUzIDE2LjY0TDE2LjQ5NDcgMTAuNDU0QzE2LjcyMzkgMTAuMjI5NCAxNi43MjM5IDkuNzY5NzYgMTYuNDk0NyA5LjU0Njk1TDEwLjE2NTMgMy4zNjA4OEMxMC4wOTYzIDMuMjkyNDYgOS45OTk5MiAzLjM1OTEzIDkuOTk5OTIgMy40NzMxNloiCiAgICAgICAgZmlsbD0iI0ZGRkZGRiIgLz4KICAgIDxwYXRoCiAgICAgICAgZD0iTTE0LjUwMzIgOS4zMzMyNUgzLjY2NjU4QzMuNDgyNDkgOS4zMzMyNSAzLjMzMzI1IDkuNDgyNDkgMy4zMzMyNSA5LjY2NjU5VjEwLjMzMzNDMy4zMzMyNSAxMC41MTczIDMuNDgyNDkgMTAuNjY2NiAzLjY2NjU5IDEwLjY2NjZIMTQuNTAyNUwxNC41MDMyIDkuMzMzMjVaIgogICAgICAgIGZpbGw9IiNGRkZGRkYiIC8+Cjwvc3ZnPg==');
  --dotted-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%238362F3' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --projects-shadow: linear-gradient(
    358.98deg,
    #f7f5ff 30%,
    rgba(247, 245, 255, 0) 100%
  );
  --small-shadow: 0px 12.52px 21.68px 0px #9f83e020,
    0px 15px 125px 0px #a88ee421;
  --shadow-default: 0px 4.52px 37.68px 0px rgba(201, 180, 250, 0.12),
  0px 15px 125px 0px rgba(201, 180, 250, 0.13);

  --error-bg: #ea4a7014;
  --error-border-hover: #ea4a7066;
  --switcher-active-bg: linear-gradient(135deg, #4ba5ff 0%, #a54bff 100%);
  --empty-bg-gradient: radial-gradient(
      51.79% 53.84% at 100% 0%,
      #dbcaff 0%,
      #dbcbff 6.67%,
      #dcccff 13.33%,
      #decfff 20%,
      #e0d2ff 26.67%,
      #e3d7ff 33.33%,
      #e7dcff 40%,
      #ebe2ff 46.67%,
      #efe8ff 53.33%,
      #f3eeff 60%,
      #f7f3ff 66.67%,
      #faf7ff 73.33%,
      #fcfbff 80%,
      #fefdff 86.67%,
      #fff 93.33%,
      #fff 100%
    ),
    radial-gradient(
      51.79% 53.84% at 100% 0%,
      color(display-p3 0.8482 0.7959 1) 0%,
      color(display-p3 0.8495 0.7976 1) 6.67%,
      color(display-p3 0.8536 0.8031 1) 13.33%,
      color(display-p3 0.8606 0.8126 1) 20%,
      color(display-p3 0.8706 0.826 1) 26.67%,
      color(display-p3 0.8834 0.8432 1) 33.33%,
      color(display-p3 0.8986 0.8636 1) 40%,
      color(display-p3 0.9154 0.8862 1) 46.67%,
      color(display-p3 0.9328 0.9096 1) 53.33%,
      color(display-p3 0.9496 0.9323 1) 60%,
      color(display-p3 0.9648 0.9527 1) 66.67%,
      color(display-p3 0.9776 0.9699 1) 73.33%,
      color(display-p3 0.9876 0.9833 1) 80%,
      color(display-p3 0.9946 0.9928 1) 86.67%,
      color(display-p3 0.9987 0.9982 1) 93.33%,
      color(display-p3 1 1 1) 100%
    );
  --empty-bg-gradient-mobile: radial-gradient(
      82.44% 76.13% at 100% 0%,
      #dbcaff 0%,
      #dbcbff 6.67%,
      #dcccff 13.33%,
      #decfff 20%,
      #e0d2ff 26.67%,
      #e3d7ff 33.33%,
      #e7dcff 40%,
      #ebe2ff 46.67%,
      #efe8ff 53.33%,
      #f3eeff 60%,
      #f7f3ff 66.67%,
      #faf7ff 73.33%,
      #fcfbff 80%,
      #fefdff 86.67%,
      #fff 93.33%,
      #fff 100%
    ),
    radial-gradient(
      82.44% 76.13% at 100% 0%,
      color(display-p3 0.8482 0.7959 1) 0%,
      color(display-p3 0.8495 0.7976 1) 6.67%,
      color(display-p3 0.8536 0.8031 1) 13.33%,
      color(display-p3 0.8606 0.8126 1) 20%,
      color(display-p3 0.8706 0.826 1) 26.67%,
      color(display-p3 0.8834 0.8432 1) 33.33%,
      color(display-p3 0.8986 0.8636 1) 40%,
      color(display-p3 0.9154 0.8862 1) 46.67%,
      color(display-p3 0.9328 0.9096 1) 53.33%,
      color(display-p3 0.9496 0.9323 1) 60%,
      color(display-p3 0.9648 0.9527 1) 66.67%,
      color(display-p3 0.9776 0.9699 1) 73.33%,
      color(display-p3 0.9876 0.9833 1) 80%,
      color(display-p3 0.9946 0.9928 1) 86.67%,
      color(display-p3 0.9987 0.9982 1) 93.33%,
      color(display-p3 1 1 1) 100%
    );
  --font-weight-bold: 500;

  --error-page-bg: url('/assets/img/System/gradient.jpg');
  --error-page-bg-mob: url('/assets/img/System/gradient-mobile.png');
  --error-page-notAuth-bg: url('/assets/img/System/gradientNotAuth.png');
  --error-page-notAuth-bg-mob: url('/assets/img/System/gradientNotAuth-mobile.png');
}

$text: #252529;
$text-dark: #323236;
$text-grey: #898989;

$red: #f5222d;
$red-middle: #ea4a70;
$red-light: #ea4a701a;
$orange: #f67d6c;
$blue-light: #6c93fd;
$blue: #247dd7;
$blue-dark: #012345;
$yellow: #f8ca14;
$green: #5ad28a;
$white: #ffffff;
$font-text: 'Grato Grotesk';
$font-currency: 'Noto';
$success-notification: linear-gradient(0deg, rgba(64, 192, 87, 0.08) 0%, rgba(64, 192, 87, 0.08) 100%), rgba(255, 255, 255, 0.90);

$xxs: 360px;
$phone: 480px;
$xs: 768px;
$xs-up: 960px;
$sm: 1024px;
$md: 1280px;
$netbook: 1366px;
$lg: 1440px;
$xl: 1920px;
$xxl: 2880px;
$extra: 3840px;

// margins between blocks

$space-0: 0px;
$space-3xs: 2px;
$space-2xs: 4px;
$space-xs: 6px;
$space-s: 8px;
$space-sm: 12px;
$space-m: 16px;
$space-l: 20px;
$space-xl: 24px;
$space-2xl: 32px;
$space-3xl: 40px;
$space-4xl: 48px;
$space-5xl: 56px;
$space-6xl: 64px;
$space-7xl: 72px;
$space-8xl: 80px;
$space-9xl: 88px;

@mixin font-72-bold {
  font-weight: 500;
  font-size: 72px;
  line-height: 130%;
}

@mixin font-64-bold {
  font-weight: 500;
  font-size: 64px;
  line-height: 130%;
}

@mixin font-54-bold {
  font-weight: 500;
  font-size: 54px;
  line-height: 130%;
}

@mixin font-52-bold {
  font-weight: 700;
  font-size: 52px;
  line-height: 62.4px;
}

@mixin font-48-bold {
  font-weight: 500;
  font-size: 48px;
  line-height: 110%;
}

@mixin font-44-bold {
  font-weight: 700;
  font-size: 44px;
  line-height: 52.8px;
}

@mixin font-40-bold {
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
}

@mixin font-40 {
  font-weight: 400;
  font-size: 36px;
  line-height: 130%;
}

@mixin font-36-bold {
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
}

@mixin font-32-bold {
  font-weight: 500;
  font-size: 32px;
  line-height: 110%;
}

@mixin font-32 {
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
}

@mixin font-30 {
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
}

@mixin font-30-bold {
  font-weight: 500;
  font-size: 30px;
  line-height: 130%;
}

@mixin font-28 {
  font-weight: 400;
  font-size: 28px;
  line-height: 130%;
}

@mixin font-28-bold {
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;
}

@mixin font-26-bold {
  font-weight: 500;
  font-size: 26px;
  line-height: 130%;
}

@mixin font-24-bold {
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
}

@mixin font-24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
}

@mixin font-22-bold {
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
}

@mixin font-22 {
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
}

@mixin font-20-bold {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
}

@mixin font-20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
}

@mixin font-18 {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

@mixin font-18-bold {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
}

@mixin font-18-extra-bold {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
}

@mixin font-16-bold {
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
}

@mixin font-16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
}

@mixin font-15 {
  font-size: 15px;
  line-height: 120%;
}

@mixin font-15-bold {
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
}

@mixin font-14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

@mixin font-14-bold {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

@mixin font-14-bold-upper {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
}

@mixin font-13-bold {
  font-size: 13px;
  font-weight: 500;
  line-height: 110%;
}

@mixin font-12 {
  font-size: 13px;
  font-weight: 400;
  line-height: 110%;
}

@mixin font-12-bold {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
}

@mixin font-12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

@mixin font-12-bold-upper {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  text-transform: uppercase;
}

@mixin font-11-bold {
  font-weight: 500;
  font-size: 11px;
  line-height: 130%;
}

@mixin font-11 {
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
}

@mixin font-10-bold-upper {
  font-weight: 500;
  font-size: 10px;
  line-height: 135%;
  text-transform: uppercase;
}

@mixin font-10-bold {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
}

@mixin font-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 100%;
}

@mixin font-9 {
  font-weight: 400;
  font-size: 9px;
  line-height: 130%;
}

@mixin font-9-bold {
  font-weight: 500;
  font-size: 9px;
  line-height: 130%;
}

$transition: 0.3s;

:root {
  --font-currency: 'Circle';
}
