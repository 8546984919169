@import '/src/styles/helpers/variables.scss';

.notice {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;

  &.success {
    background: linear-gradient(
        0deg,
        rgba(0, 200, 83, 0.04),
        rgba(0, 200, 83, 0.04)
      ),
      #ffffff;
    border: 1px solid $green;
  }

  &.warning {
    background: linear-gradient(
        0deg,
        rgba(243, 202, 55, 0.04),
        rgba(243, 202, 55, 0.04)
      ),
      #ffffff;
    border: 1px solid #f3ca37;
  }

  &.error {
    background: linear-gradient(
        0deg,
        rgba(245, 34, 45, 0.04),
        rgba(245, 34, 45, 0.04)
      ),
      #ffffff;
    border: 1px solid #f5222d;
  }
}

.icon {
  margin-right: 12px;
  display: flex;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.text {
  position: relative;
  top: 2px;
}
