// svg {
//     use {
//        fill: inherit;
//     }
// }

// path, g {
//     fill: inherit;
// }

.icon {
  &-item {
    margin-bottom: 40px;
  }
}
