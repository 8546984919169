@import 'styles/helpers/variables.scss';

.form {
  position: relative;

  .fields {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input-field {
    position: relative;

    .password {
      @include font-12-bold;

      a {
        color: var(--accent-color);
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .button {
    height: 40px;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  bottom: 4px;
  z-index: 10;
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 28px;
  padding: 8px 16px;
}

.input-field {
  position: relative;
  .item-label {
    @include font-12-bold;
    margin-bottom: 4px;
  }
}

.show-password {
  position: absolute;
  right: 13px;
  bottom: 20px;
  z-index: 10;
  cursor: pointer;

  svg {
    display: block;
  }
}

.password-check {
  position: absolute;
  left: 330px;
  top: -120px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
    top: -100px;
  }

  .title {
    @include font-14-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-14;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}

.repeat-check {
  position: absolute;
  left: 330px;
  top: -69px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
  }

  .title {
    @include font-14-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-14;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}

.row {
  &:last-child {
    margin-bottom: 0;
  }
}

.titleWrap {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .description {
    @include font-14;
    color: var(--text-color);
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  @include font-22-bold;
  color: var(--text-color);
}

.field {
  position: relative;
  max-width: 600px;

  .item-label {
    @include font-12-bold;
    margin-bottom: 4px;
    color: var(--text-color);
  }

  .input {
    @include font-14;
    input {
      height: 40px;
      width: 100%;
    }
  }
}

.notice {
  position: absolute;
  top: 10px;
  right: 0;
}

.box {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  @media screen and (max-width: $xs-up) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.inner {
  @media screen and (max-width: $xs-up) {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 280px);
    justify-content: space-between;
  }
}
