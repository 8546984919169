@import 'styles/helpers/variables.scss';

.wrap {
  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: var(--error-page-bg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $xs-up) {
      background-image: var(--error-page-bg-mob);
    }
  }

  .logo {
    margin-top: $space-3xl;
    margin-bottom: $space-9xl;
    display: flex;
    justify-content: center;
    pointer-events: none;

    svg {
      width: unset;
      height: unset;
      z-index: 2;
    }
  }

  &.isNotAuth {
    margin: 0 auto;

    &:after {
      background-image: var(--error-page-notAuth-bg);

      @media screen and (max-width: $xs-up) {
        background-image: var(--error-page-notAuth-bg-mob);
      }
    }
  }
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  margin-top: 100px;

  .button {
    padding: 10px 20px;
    margin-top: 20px;
  }
}

.title {
  @include font-36-bold;
  text-align: center;
  display: block;
  margin-bottom: 16px;
}

.subtitle {
  @include font-24;
  margin: 0;
  max-width: 460px;
  text-align: center;
}

.img {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  display: flex;
  justify-content: center;

  img {
    display: block;
  }

  @media screen and (max-width: $xs-up) {
    width: 100%;
    max-width: 40%;

    img {
      width: 100%;
    }
  }
}
