@import '/src/styles/helpers/variables.scss';

.cookie-notice {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  padding: 32px 0;
  border-radius: 36px 36px 0px 0px;
  box-shadow:
    0px -2.19px 6.6501px 0px rgba(28, 20, 37, 0.05),
    0px -7.37px 22.33631px 0px rgba(28, 20, 37, 0.03),
    0px -33px 100px 0px rgba(28, 20, 37, 0.02);
  background-color: $white;

  @media screen and (max-width: 768px) {
  }

  &__container {
    width: 100%;
    max-width: 1296px;
    padding: 0 32px;
    margin: 0 auto;
  }

  &__head {
    display: flex;
    gap: 12px;
    margin: 0 0 16px;
  }

  &__icon {
    position: relative;
    left: -3px;
    top: -3px;
    height: 40px;

    @media screen and (max-width: 768px) {
      top: -6px;
    }
  }

  &__iconRu {
    svg {
      path {
        fill: var(--accent-color);
      }
    }
  }

  &__title {
    margin: 0;
  }

  &__text {
    @include font-14();
    color: var(--text-color);
  }

  &__link {
    position: relative;
    display: inline-block;
    color: var(--accent-color);

    a {
      color: var(--accent-color);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid var(--accent-color);
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__block-btn {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 1024px) {
      margin-top: 16px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__btn {
    width: 100%;
    max-width: 200px;
    height: 40px;

    @media screen and (max-width: 768px) {
      min-width: 100%;
    }
  }
}
