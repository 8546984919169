@import '/src/styles/helpers/variables.scss';

.map {
  position: relative;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent-color);
    @include font-12-bold;

    @media (max-width: 1024px) {
      display: block;

      svg {
        margin-bottom: -2px;
      }
    }

    svg {
      stroke: var(--accent-color);
    }
  }

  .step {
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    margin-left: 0.5em;

    &__date {
      text-align: right;
      text-wrap: balance;
      @include font-11;
      width: 85px;
    }

    &__block {
      margin-top: 1px;

      span {
        color: var(--text-color);
        @include font-14-bold;
      }

      p {
        margin: 0;
        margin-bottom: 6px;
        @include font-12;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .empty {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(var(--text-light), 0.2);
    }

    &_empty {
      &::after {
        content: '';
        opacity: 0.2;
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: calc(100% + 2px);
      position: absolute;
      top: 18px;
      left: 7.3em;
      background-color: var(--accent-color);
    }

    &:last-child {
      margin-bottom: 0px;

      &::after {
        content: none;
      }
    }

    .name {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      @include font-14-bold;

      span {
        padding-top: 3px;
      }

      > svg {
        fill: var(--accent-color);
        width: 20px;
        height: 20px;
      }
    }

    .past {
      color: var(--text-light);
    }
  }
}

@media (max-width: 1024px) {
  .map {
    .step {
      &__date {
        max-width: 53px;
        margin-right: 8px;
        flex-shrink: 0;
      }
      svg {
        flex-shrink: 0;
      }
      &::after {
        left: 5.55em;
      }
    }
  }
}
