@import 'styles/helpers/variables.scss';

.modal {
  position: fixed;
  top: 0;
  z-index: 91;
  display: none;
  width: 100vw;
  height: 103vh;
  background: rgba(13, 9, 24, 0.56);
  overflow-y: auto;
  transition: 0.6s;
  color: var(--text-color);

  @media screen and (min-width: 1025px) {
    left: 0;
    right: unset;
    display: none;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
    height: 103vh;
  }

  &.price-modal {
    left: 0;
    width: 101vw;
    right: unset;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
  }
}

.bottom-modal {
  position: fixed;
  left: 0;
  bottom: -1000px;
  width: 100vw;
  height: 100vh;
  padding: 12px;
  background-color: $white;
  overflow-y: auto;
  z-index: 100;
  transition: 0.6s;
  visibility: hidden;
}

.bottom-open {
  visibility: visible;
  bottom: 0px;

  .header {
    bottom: 12px;
    transition: 0.6s;
  }
}

.open {
  right: 0px;
  display: block;

  .header {
    display: none;
    right: 12px;
    transition: 0.6s;
  }

  @media screen and (min-width: 1025px) {
    right: unset;
    display: flex;
  }

  &.price-modal {
    right: unset;
    display: flex;

    .header {
      display: none;
    }

    .content {
      background-color: $white;
      border-radius: 20px;
      max-width: max-content;
      margin: auto;
      width: 100%;
      position: relative;
      padding: 0;
      height: unset;
    }

    .desktop {
      display: flex;
    }
  }
}

.header {
  position: fixed;
  z-index: 100;
  top: 12px;
  right: -1000px;
  width: calc(100% - 24px);
  height: 56px;
  background-color: $white;
  box-shadow: var(--top-shadow);
  border-radius: 28px;
  padding: 16px;

  @media screen and (min-width: 1025px) {
    display: none;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      @include font-14-bold;

      svg {
        width: 24px;
        height: 24px;
        display: block;
      }
    }

    .close {
      width: 21px;
      height: 21px;

      svg {
        fill: var(--text);
      }
    }
  }
}

.basic {
  box-shadow: none;
  border-radius: 0;
}

.content {
  padding: 32px 20px 64px 20px;
  width: 100%;
  z-index: 1;
  min-height: fit-content;
  max-height: 80dvh;
  background-color: $white;
  position: fixed;
  border-radius: 24px 24px 0 0;
  transform: translateY(100%);
  opacity: 0;
  animation: slideUp 0.3s ease forwards;

  &.no-padding {
    padding: 0 !important;
    height: 248px !important; 
  }

  @media screen and (min-width: 1025px) {
    background-color: $white;
    border-radius: 36px;
    max-width: max-content;
    margin: auto;
    width: 100%;
    position: relative;
    padding: 0;
    height: unset;
  }

  @media screen and (max-width: $xs-up) {
    bottom: 0;
  }
}

.sub-modal {
  z-index: 110;
  height: 100vh;
  padding-bottom: 60px;

  .header {
    right: 12px;
    transition: 0.6s;
  }
}

.cross {
  border-radius: 50%;
  background-color: #ffffff;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10000;
  position: absolute;
}

.desktop {
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $white;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10000;

  @media screen and (min-width: 1025px) {
    display: flex;
    right: -65px;
    top: 0px;
  }
}

.mobile-filter {
  height: 100%;
  overflow: hidden;

  .content {
    overflow: auto;
  }
}

.on-center-modal {
  z-index: 10000;
}

.on-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  border-radius: 24px;
  padding-bottom: 0px;
  animation: slideUpCenter 0.3s ease forwards;

  @media screen and (max-width: $xs-up) {
    width: 320px;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUpCenter {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.mobileHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @include font-18-extra-bold;
  color: var(--text-color);
}