@import 'styles/helpers/variables.scss';

.filters {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 8px;

  &__btn {
    display: flex;
    align-items: end;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;

    &:nth-child(1) {
      gap: 6px;
    }

    &:nth-child(2) {
      margin-left: auto;
      margin-right: 12px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--holder-color);
        }
      }
    }
  }
}

.invoices-list {
  display: flex;
  flex-direction: column;

  .invoice {
    padding: 20px 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #f7f5ff;
    }

    &__date {
      display: inline-block;
      color: var(--text-light);
      @include font-12;
      margin-bottom: 12px;
    }

    &__client {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 12px;

      &_name {
        display: inline-block;
        color: var(--accent-color);
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include font-18-bold;
      }

      &_num {
        color: var(--holder-color);
        @include font-11;
      }
    }

    &__number {
      display: flex;
      gap: 4px;
      color: var(--text-color);
      @include font-14-bold;

      svg {
        width: 16px;
        height: 16px;
        stroke: var(--accent-color);
      }
    }

    &__description {
      color: var(--holder-color);
      @include font-12;
    }

    &__payment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      &_price {
        display: block;
        @include font-24-bold;
        margin-bottom: 4px;
      }

      &_diff {
        display: block;
        color: var(--holder-color);
        @include font-12;
      }

      button {
        padding: 6px 24px 6px 20px;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.sorting-items {
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    border-bottom: 1px solid var(--accent-third);
    &:last-child {
      border-bottom: none;
    }
  }

  &__button {
    display: flex;
    align-items: flex-start;
    @include font-16;
    padding: 12px 0px;
    // text-align: left;
    color: var(--text-color);
  }
}

.search-wrapper {
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  input {
    font-size: 15px;
  }

  button {
    height: 48px;
    @include font-16-bold;
  }
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  &:not(:first-child) {
    padding-top: 16px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--accent-third);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include font-16-bold;

    button {
      font-weight: 400;
    }
  }

  &__checklist {
    padding: 0;

    li {
      label {
        span:last-child {
          transform: translateY(-3px);
          font-size: 15px;
        }
      }
    }
  }

  &__submit {
    margin-top: 52px;
    height: 48px;
    @include font-16-bold;
  }
}
