.accordion {
  width: 100%;

  &__header {
    display: flex;
    flex-shrink: 0;
    padding: 12px;
    position: relative;
    &_reverse {
      flex-direction: row-reverse;
    }
  }

  .zero-padding {
    padding: 0px;
  }

  &__arrow {
    display: inline-block;
    align-self: center;
    transition: all 0.2s;
    cursor: pointer;
    margin-left: auto;

    &_up {
      transform: rotate(180deg) translateY(-3px);
    }

    &_reverse {
      margin-left: unset;
      margin-right: auto;
    }

    svg {
      width: 12px;
      height: 6px;
    }
  }

  &__content {
    margin: 0 auto;
    overflow: hidden;
    transition: all 0.2s;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
    height: 0;

    &_expanded {
      transform-origin: top;
      transform: scaleY(1);
      opacity: 1;
      height: auto;
    }
    &_scrollable {
      overflow: scroll;
      max-height: 500px;
    }
  }
}
