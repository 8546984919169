@import '../../styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .logo {
    position: fixed;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      width: 40px;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: var(--start-gradient);
  padding: 141px 48px;

  .image {
    object-fit: cover;
    width: calc(100% - 96px);
    position: absolute;
    right: 48px;
    left: 48px;

    @media screen and (max-width: 1024px) {
      width: 306px;
      left: 15%;
      top: 0;
      height: 266px;
    }
  }

  .image-two {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
  }

  .left-title {
    color: $white;
    margin-bottom: 16px;
    @include font-20;

    @media screen and (max-width: 1024px) {
      @include font-12;
      max-width: 211px;
    }
  }

  .left-text {
    color: $white;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-14-bold;
      text-align: start;
      max-width: 211px;
    }
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 288px;
    position: fixed;
    padding: 92px 16px;
  }
}

.right {
  position: relative;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  .lang {
    position: absolute;
    top: 48px;
    right: 48px;
    height: 30px;
  }

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 24px 16px;
    border-radius: 28px 28px 0px 0px;
    overflow: hidden;
    margin-top: 260px;
    background-color: white;
    z-index: 11;

    position: unset;

    .lang {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .title {
    color: var(--text-color);
    margin-bottom: 4px;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;
    color: var(--text-color);

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-14-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
  .multiselect-lang {
    display: flex;
    justify-content: flex-end;
  }
}

.word-logo {
  width: 152px;
  margin-left: 4px;

  @media screen and (max-width: 1024px) {
    width: 114px;
  }
}

.form {
  .fields {
    display: grid;
    row-gap: 32px;
    margin-bottom: 40px;
  }

  .input-field {
    position: relative;
    .item-label {
      @include font-12-bold;
      margin-bottom: 4px;
      color: var(--text-color);
    }

    input {
      height: 38px;
      border: 1px solid var(--accent-second);
      &::placeholder {
        color: var(--holder-color);
      }
      &:hover {
        border: 1px solid var(--accent-color);
      }
    }

    .password {
      position: absolute;
      right: 0;
      top: 0;
      @include font-14-bold;

      a {
        color: var(--accent-color);
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .check {
    margin-top: 20px;
    width: fit-content;
  }

  .button {
    height: 40px;

    &:disabled {
      background: var(--accent-third);
      color: var(--text-light);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
}

.return-button {
  a {
    @include font-12-bold;
    display: flex;
    color: var(--text-light);
    text-transform: uppercase;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;

    svg {
      width: 14px;
      height: 14px;

      path {
        fill: var(--text-light);
      }
    }
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: 6px;
  z-index: 10;

  :global(.common__tooltip.top) {
    top: -15px;
  }

  :global(.common__tooltip-inner) {
    padding: 6px 12px;
    box-shadow: none;
    border: 1px solid #f3ecff;
    max-width: max-content;

    &::after {
      content: none;
    }
  }

  @media screen and (max-width: 1024px) {
    bottom: 5px;

    :global(.common__tooltip.top) {
      left: -60px;
    }
  }
}

.captcha {
  position: relative;
  margin-top: 36px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 28px;
  padding: 8px 16px;
}

.body {
  .title {
    @include font-22-bold;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
    }
  }

  .email {
    display: flex;
  }

  .text {
    @include font-20;
    margin-bottom: 24px;
    color: var(--text-color);

    span {
      margin-left: 4px;
      @include font-20-bold;
    }
  }

  .help {
    @include font-20;
    color: var(--text-color);

    .link {
      margin-left: 3px;
      color: var(--accent-color);
      cursor: pointer;
      @include font-20-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
  @include font-22-bold;
  color: var(--text-color);

  @media screen and (max-width: 1024px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  svg {
    width: 64px;
    height: 53px;

    @media screen and (max-width: 1024px) {
      width: 64px;
      height: 53px;
    }
  }
}
