@import 'styles/helpers/variables.scss';

.input {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--accent-second);
  background: var(--accent-light);
  height: 28px;
  color: var(--text-color);
  cursor: pointer;
}
