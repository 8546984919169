@import '../../../styles/helpers/variables.scss';

.noticeContainer {
  display: flex;
  border-radius: 12px;
  padding: 24px;
  color: var(--text-color);
  gap: 16px;

  .icon {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    flex-shrink: 0;
  }

  &.waiting {
    background: var(--ui-01-8);
    .icon {
      background: var(--notice-waiting-gradient);
    }
  }

  &.warning {
    background: var(--ui-03-8);
    .icon {
      background: var(--notice-warning-gradient);
    }
  }

  &.default {
    background: var(--ui-04-8);

    .icon {
      background: var(--notice-default-gradient);
    }
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .noticeTitle {
      @include font-14-bold;
    }

    .noticeDescription {
      @include font-12;
    }
  }

  @media screen and (max-width: $sm) {
    padding: 20px;
    margin: 20px;
  }
}
