@import '../../../styles/main';

.inputWrapper {
  .input {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 8px 12px;
    border-radius: 12px;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    @include font-14;
    background: var(--accent-light);
    color: var(--text-color);
    border: 1px solid transparent;
    transition: 0.3s;
    font-size: 16px;

    &.disabled {
      background: var(--accent-light-disabled) !important;
      &:hover {
        border: 1px solid transparent;
        background: var(--accent-light-disabled) !important;
      }
    }

    &.isSearchInput {
      min-height: unset;
      padding: 8px;
      gap: 4px;
      background: #ffffff;
      border-color: var(--accent-second);
      border-radius: 8px;
    }

    &::placeholder,
    input::placeholder {
      color: var(--holder-color);
    }

    &:hover {
      border: 1px solid var(--accent-second);
      background: #ffffff !important;
    }

    &.active {
      border: 1px solid var(--accent-color);
      background: #ffffff;
    }

    &.error {
      background: var(--error-bg);

      &:hover {
        border-color: var(--error-border-hover);
      }

      &.active {
        border: 1px solid var(--error-color);
        background: #ffffff;
      }
    }

    .inputField {
      background: inherit;
      border: none;
      width: 100%;
      padding-left: 0.5px;
    }
  }

  .errorText {
    font-size: 12px;
    margin-top: 4px;
    color: #ea4a70;
  }
}

.inputBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }

  .inputTitle {
    @include font-12;
    color: var(--holder-color);
  }
}

.textareaWrap {
  .textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--accent-light);
    border-radius: 12px;
    color: var(--text-color);
    font-size: 14px;
    padding: 8px 12px;
    transition: 0.3s;
    width: 100%;
    min-height: 72px;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--accent-second);
      background: #ffffff !important;
    }

    &.focused {
      border: 1px solid var(--accent-color);
      background: #ffffff;
    }

    .textareaInput {
      height: 100%;
      width: 98%;
      border: none;
      background: transparent;
      resize: none;
      padding-left: 0;

      &::placeholder,
      input::placeholder {
        color: var(--holder-color);
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--accent-third);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--accent-second);
      }
    }

    &.textareaError {
      background: var(--error-bg);

      &:hover {
        border-color: var(--error-border-hover);
      }

      &.focused {
        border: 1px solid var(--error-color);
        background: #ffffff;
      }

      .textareaInput {
        background: transparent;
      }
    }
  }

  .errorText {
    font-size: 12px;
    margin-top: 4px;
    color: #ea4a70;
  }
}

.startIcon {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--holder-color);
    }
  }
}

.endIcon {
  display: flex;

  svg {
    width: 20px;
    height: 20px;

    path {
      stroke: var(--gray-color);
      stroke-width: 1.3px;
    }
  }

  &.error {
    path {
      stroke: var(--error-color);
    }
  }
}
