@import 'styles/helpers/variables.scss';

.tooltipContainer {
  position: relative;
}

.tooltip {
  z-index: 101;
  width: 220px;
  :global(.common__tooltip-inner) {
    padding: 12px 6px;
    pointer-events: all;
  }

  :global(.common__tooltip-text) {
    width: 100%;
    display: block;
    color: var(--text-color);
  }
}

.sort {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--accent-light);

  &__item {
    display: flex;
    padding: 2px 6px;
    align-items: center;
    gap: 6px;
    color: var(--text-color);
    @include font-14;
    &--down {
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }
  }
}
.content {
  width: 100%;
  padding-top: 8px;
}

.border-none {
  border: none;
  padding: 0px;
}
