@import 'styles/helpers/variables.scss';

.item-wrapper {
  @include font-14;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;

  .orderBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: var(--accent-color);
    color: $white;
    @include font-9-bold;
    pointer-events: none;
  }

  &_hovered {
    background: var(--accent-third);
    width: 100%;
  }

  .checkbox {
    pointer-events: none;
    svg {
      path {
        stroke: white;
      }
    }
  }
}

.item-content {
  width: 100%;
  color: var(--text-color);
}
