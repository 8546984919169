@import 'styles/helpers/variables.scss';

.textEditorWrapper {
  width: 100%;
  height: 104px;
  border-radius: 12px;
  background: var(--accent-light);
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  .toolbar {
    margin: 8px 12px;

    .buttonGroup {
      background: var(--base-white);
      display: flex;
      flex-direction: row;
      gap: 12px;
      border-radius: 4px;
      padding: 8px 12px;
      flex-wrap: wrap;

      .divider {
        width: 1px;
        height: 20px;
        background-color: var(--text-light);
        display: flex;
        margin-top: 2px;
      }

      &__russian {
        .tooltipContent {
          left: -40px;
          min-width: 110px;
          .tooltipInner {
            max-width: 110px;
          }
        }
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 8px;
        transition: background-color 0.2s;
        z-index: 9;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: unset;

        &:hover {
          background: var(--accent-light);
        }

        &.active {
          background: var(--accent-color);

          svg {
            fill: var(--base-white);
            color: var(--base-white);
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          display: flex;
        }

        svg {
          width: 100%;
          height: 100%;
          fill: var(--text-light);
          color: var(--text-light);
        }
      }
    }
  }

  .tooltip {
    position: relative;
    width: 32px;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .tooltipContent {
      transform: translate(0, 100%);
      left: -25px;
      min-width: 80px;
      box-shadow: var(--small-shadow);

      .tooltipInner {
        max-width: 80px;
        @include font-11;
        padding: 6px 12px;
      }

      @media screen and (max-width: $xs) {
        display: none;
      }
    }
  }

  &:hover {
    border: 1px solid var(--accent-second);
    background: #ffffff !important;
  }

  &.focused {
    border: 1px solid var(--accent-color);
    background: #ffffff;
  }

  .content {
    width: 100%;
    height: 100%;
    cursor: text;
  }

  .endIcon {
    display: flex;
    margin-right: 12px;

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: var(--gray-color);
        stroke-width: 1.3px;
      }
    }

    &.error {
      path {
        stroke: var(--error-color);
      }
    }
  }

  &.withContent {
    height: fit-content;

    .label {
      @include font-12;
    }
  }

  .editorContainer {
    margin: 12px 0;
    height: 100%;
    width: 100%;
  }

  .hidden {
    display: none;
  }

  &.focused {
    height: 228px;
    &.withContent {
      min-height: 228px;
      height: fit-content;
      display: flex;
      align-items: flex-start;
    }
  }

  .label {
    margin-top: 12px;
    margin-left: 12px;
    color: var(--text-light);
    @include font-14;
    &.focused {
      margin-left: 0;
    }

    &_centered {
      margin-top: 17px;
    }
  }

  &.error {
    background: var(--error-bg);

    &:hover {
      border-color: var(--error-border-hover);
    }

    &.focused {
      border: 1px solid var(--error-color);
    }
  }
}

.errorText {
  font-size: 12px;
  margin-top: 8px;
  color: #ea4a70;
}
