@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;

  @media screen and (max-width: $xs-up) {
    padding-top: 20px;
    height: 100%;
  }
}

.innerBlock {
  background: #ffffff;
  box-shadow: var(--top-shadow);
  border-radius: 36px;
  padding: 40px;

  @media screen and (max-width: $xs-up) {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    height: 100%;
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;
}

:global(.common__tooltip--hidden) {
  right: 0;
  bottom: 0;
  left: 0;
  top: unset;
  width: 20px;
  height: 20px;
}
