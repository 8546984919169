@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  padding: 40px 0;

  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: inherit;
    margin-top: 32px;

    &__icon {
      svg {
        width: 265px;
        height: 138px;
      }
    }

    &__text {
      @include font-16-bold;
      font-weight: var(--font-weight-bold);
      color: var(--text-color);
    }

    &__description {
      @include font-14;
      color: var(--holder-color);
    }

    @media screen and (max-width: 1024px) {
      margin-top: 40px;

      &__icon {
        svg {
          width: 140px;
          height: 75px;
        }
      }

      &__text {
        @include font-14-bold;
        font-weight: var(--font-weight-bold);
        color: #363232;
      }

      &__description {
        @include font-12;
        color: var(--holder-color);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 54px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    padding: 0 0 54px;
  }
}

.back {
  margin-bottom: 24px;
  @include font-12-bold;

  a {
    display: inline-flex;
    align-items: center;
    padding: 8px 24px 8px 0;
    gap: 8px;
    color: var(--accent-color);
    line-height: 1.5;
    svg {
      fill: var(--accent-color);
    }
  }
}

.tabs {
  justify-content: center;
}

.title {
  @include font-32-bold;
  position: relative;
  color: var(--text-color);
  
  @media screen and (max-width: 1024px) {
    @include font-32-bold;
  }
}

.search-term {
  color: #898989;
}

.tab-name {
  @include font-22-bold;
  margin-top: 32px;
  margin-bottom: 16px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 30px;

  &.projectList {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1340px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 724px) {
    grid-template-columns: 1fr;
  }
}

.item-orders {
  @media screen and (max-width: 450px) {
    max-width: calc(100% - 40px);
  }

  @media screen and (max-width: 375px) {
    max-width: calc(100% - 60px);
  }
}

.no-results {
  @include font-14;
  color: #898989;
}

.pagination {
  width: 100%;
  margin: 32px 0 0 0;
  display: flex;
  gap: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .button {
    height: 32px;
  }
}

.projects-wrapper {
  // margin-top: 40px;
  border-radius: 36px;
  background: $white;
  box-shadow: var(--top-shadow);
  padding: 24px 24px 40px 24px;
  @include font-12;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding: 24px 20px;
  }

  @media screen and (max-width: 680px) {
    margin: 24px -20px 0px -20px;
  }
}
