@import 'styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .logo {
    position: absolute;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      width: 40px;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: linear-gradient(183.88deg, #65b2ff 1.64%, #6638f4 96.82%);

  @media screen and (max-width: 1024px) {
    z-index: -1;
    width: 100%;
    min-height: 288px;
    padding-top: 30px;
  }

  .image {
    object-fit: cover;
    width: 100%;
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }
}

.right {
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    z-index: 1;
    width: 100%;
    padding: 24px 16px;
    margin-top: -28px;
    border-radius: 28px 28px 0px 0px;
    background-color: white;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
  }

  .steps {
    display: flex;
    gap: 4px;
    color: var(--text-light);
    text-transform: uppercase;
    margin-bottom: 20px;
    @include font-12-bold;

    .arrow {
      width: 14px;
      height: 14px;
      path {
        fill: var(--text-light);
      }
    }
  }

  .title {
    color: var(--text-color);
    margin-bottom: 4px;
    @include font-28-bold;

    @media screen and (max-width: 1024px) {
      @include font-24-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;
    color: var(--text-color);

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-14-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}

.lang {
  position: absolute;
  top: 48px;
  right: 48px;
  z-index: 500;

  @media screen and (max-width: 1024px) {
    top: 16px;
    right: 16px;
  }
}

.invitation {
  padding: 4px 12px;
  border-radius: 36px;
  @include font-11-bold;
  color: var(--accent-color);
  background-color: var(--accent-light);
  margin-bottom: 20px;
  width: fit-content;
}
