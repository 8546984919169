@import 'styles/helpers/variables.scss';

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 0px 6px;

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-color);
    @include font-12-bold;

    svg {
      width: 14px !important;
      height: 14px !important;
      transform: translateY(4px);

      path {
        fill: var(--text-color);
      }
    }

    &--search {
      color: #9e93ab;
    }
  }

  &__input {
    width: 100%;
    input {
      padding: 4px 8px;
      font-size: 13px;
    }
  }
}

.tooltip_right {
  @media screen and (max-width: 1500px) {
    transform: translate(10%, 35%) !important;
    :global(.common__tooltip-inner) {
      &::after {
        transform: rotate(90deg) translate(200%, 600%) !important;
      }
    }
  }
}
