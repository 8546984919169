@import '../../../styles/main.scss';

.formhint {
  width: 100%;
  color: #252529;
  display: flex;
  @include font-14-bold;
  margin-top: 4px;
}
.formhint.left {
  justify-content: flex-start;
}
.formhint.right {
  justify-content: flex-end;
}

.input {
  width: 100%;

  &.accountForm {
    input {
      padding: 8px 12px;
      background: var(--accent-light);

      &:hover {
        background: var(--accent-light) !important;
        border-color: var(--accent-color);
      }

      &.focused {
        background: var(--base-white);
      }

      &:disabled:hover {
        background: var(--accent-light);
        border-color: var(--accent-color);
      }

      &::placeholder {
        color: var(--holder-color);
      }
    }

    &.focused {
      input {
        background: var(--base-white) !important;
      }
    }
  }

  input {
    background: var(--accent-light);
    border: 1px solid var(--accent-second);
    border-radius: 8px;
    color: #252529;
    @include font-14;
    padding: 8px 12px;
    transition: 0.3s;
    width: 100%;
    &:focus {
      border-color: var(--accent-color);
      background-color: $white;
    }

    @media screen and (max-width: $xs) {
      @include font-16;
    }
  }
}

.textarea {
  background: #f7f7fa;
  border: 1px solid var(--accent-second);
  border-radius: 8px;
  color: #252529;
  font-size: 16px;
  padding: 8px 12px;
  transition: 0.3s;
  width: 100%;
}

.inputicon,
.formtextarea.icon {
  padding-left: 40px;
}

.inputWrap,
.formtextareaWrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputInner,
.formtextareaInner {
  position: relative;
}

.input:disabled,
.formtextarea:disabled {
  cursor: default;
  opacity: 0.5;
}

.input:disabled:hover,
.formtextarea:disabled:hover {
  border-color: #e4e4e5;
}

.input:hover,
.form__textarea:hover,
.input:focus,
.form__textarea:focus {
  input:hover {
    border-color: var(--accent-color);
    background-color: $white;
  }
}

.input::placeholder,
.form__textarea::placeholder {
  color: #898989;
}

.inputError,
.textareaError {
  border-color: #f5222d !important;
  padding-right: 43px;
}

.textarea {
  resize: none;
}

.iconWrap {
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
