@import 'styles/helpers/variables.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 20px;
  background: var(--accent-light);
  color: var(--text-light);
  height: 50%;
  @include font-12;

  &__ru {
    height: 100%;

    .empty {
      &__content {
        margin: 24px auto;

        svg {
          width: 122px !important;
        }
      }
    }
  }

  .empty {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      gap: 8px;

      svg {
        width: 80px;
        height: auto;
      }

      @media screen and (max-width: 768px) {
        margin: 0;
        margin-top: 12px;
      }
    }
  }

  .title {
    margin-bottom: 4px;
    @include font-12;
  }

  .company {
    display: flex;
    flex-direction: column;

    &__name {
      color: var(--text-color);
      margin-bottom: 4px;
      @include font-16-bold;
    }
  }

  .bank {
    display: flex;
    gap: 40px;

    &__details {
      &_title {
        @include font-12;
        margin-bottom: 4px;
      }

      &_number {
        display: flex;
        gap: 4px;
        color: var(--text-color);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 24px;
    justify-content: unset;

    .bank {
      flex-direction: column;
      gap: 16px;
    }
  }
}
