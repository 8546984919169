@import 'styles/helpers/variables.scss';

.caseWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  .hint {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--1,
    &--2 {
      box-sizing: border-box;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--text-color);

    .deleteButton {
      color: var(--accent-color);
      @include font-14-bold;
      background: transparent;
      border: none;

      &.disabled {
        color: rgba(var(--main-rgb), 0.5);
      }
    }

    &__withCounter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      @include font-16-bold;

      .counter {
        @include font-14;
        color: var(--text-light);
      }
    }
  }
  .formBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .textarea {
      background: #f7f5ff;
      border: none;
    }

    .multiselectWrapper {
      min-height: 56px;
      cursor: pointer;

      &__input {
        border-color: transparent;

        &:hover {
          border-color: #ddc8ff;
        }
      }
    }
  }

  .select {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;
}
