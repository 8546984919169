@import '/src/styles/helpers/variables.scss';

.logo {
  display: flex;
  flex-shrink: 0;
  max-height: 33px;

  svg {
    height: 100%;
  }

  @media screen and (max-width: $xs) {
    height: 26px;
  }

  img {
    display: block;
    width: 100%;
  }
}
