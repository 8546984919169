@import 'styles/helpers/variables.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0d09188f;
  z-index: 102;
  animation: fadeIn 0.3s ease-out;
}

.overlay.close {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 24px 24px 0 0;
  width: 100%;
  height: auto;
  padding: 32px 20px;
  z-index: 103;
  animation: slideUp 0.3s ease-out;

  .title {
    @include font-20-bold();
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.close {
    animation: slideDown 0.3s ease-out;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
