@import '../../styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .logo {
    position: fixed;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      width: 40px;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  position: relative;
  width: 43%;
  background: var(--start-gradient);
  padding: 141px 48px;

  .image {
    object-fit: cover;
    width: calc(100% - 96px);
    position: absolute;
    right: 48px;
    left: 48px;

    @media screen and (max-width: 1024px) {
      width: 306px;
      left: 15%;
      top: 0;
      height: 266px;
    }
  }

  .image-two {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
  }

  .left-title {
    color: $white;
    margin-bottom: 16px;
    @include font-20;

    @media screen and (max-width: 1024px) {
      @include font-12;
      max-width: 211px;
    }
  }

  .left-text {
    color: $white;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-14-bold;
      text-align: start;
      max-width: 211px;
    }
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 288px;
    position: fixed;
    padding: 92px 16px;
  }
}

.right {
  position: relative;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  .lang {
    position: absolute;
    top: 48px;
    right: 48px;
    height: 30px;
  }

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 24px 16px;
    border-radius: 28px 28px 0px 0px;
    // overflow: hidden;
    margin-top: 260px;
    background-color: white;
    z-index: 11;

    position: unset;

    .lang {
      top: 16px;
      right: 16px;
    }
  }

  .title {
    color: var(--text-color);
    margin-bottom: 4px;
    @include font-30-bold;

    @media screen and (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 32px;
    color: var(--text-color) !important;

    span {
      display: inline-block;
    }

    .link {
      //margin-left: 8px;
      @include font-14-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}

.form {
  position: relative;

  .fields {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input-field {
    position: relative;

    input {
      height: 38px;
      border: 1px solid var(--accent-second);
      &::placeholder {
        color: var(--text-light);
      }
      &:hover {
        border: 1px solid var(--accent-color);
      }
    }

    .password {
      @include font-14;

      .item-label {
        @include font-12-bold;
        margin-bottom: 4px;
        color: var(--text-color);
      }

      a {
        color: var(--accent-color);
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .button {
    height: 40px;

    &:disabled {
      background: var(--accent-third);
      color: var(--text-light);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  bottom: 4px;
  z-index: 10;
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 28px;
  padding: 8px 16px;
}

.body {
  .title {
    @include font-22-bold;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
    }
  }

  .text {
    @include font-14;
    margin-bottom: 12px;

    span {
      @include font-14-bold;
    }
  }

  .help {
    @include font-14;

    .link {
      margin-left: 3px;
      color: var(--accent-color);
      cursor: pointer;
      @include font-14-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 45px;
  @include font-32-bold;

  @media screen and (max-width: 1024px) {
    @include font-22-bold;
  }

  svg {
    width: 64px;
    height: 53px;

    @media screen and (max-width: 1024px) {
      width: 64px;
      height: 53px;
      margin-bottom: 18px;
    }
  }
}

.input-field {
  position: relative;
}

.show-password {
  position: absolute;
  right: 13px;
  bottom: 10px;
  z-index: 10;
  height: 14px;
  cursor: pointer;
}

.bottom-fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
}

.password-check {
  position: absolute;
  right: 0;
  top: -140px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
    top: -125px;
    padding: 11px;
  }

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}

.repeat-check {
  position: absolute;
  right: 0;
  top: -70px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  @media screen and (max-width: 1024px) {
    left: auto;
    right: 0;
    top: -57px;
    padding: 11px;
  }

  .title {
    @include font-12-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-12;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}
