@import '/src/styles/helpers/variables.scss';

.politic-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 10px;

  &__link {
    @include font-12;
    color: var(--text-light);
    transition: $transition;

    &:hover {
      color: var(--text-color);
    }
  }
}
