@import 'styles/main.scss';

.swiper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 65px;

  @media screen and (max-width: 1400px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
    .swiper-slider {
      align-items: unset;
    }
  }
}

.swiper {
  position: relative;
  height: 100%;
  min-height: 610px;
  width: 100%;
  max-width: 490px;

  @media screen and (max-width: 1024px) {
    height: 288px;
    min-height: auto;
  }
}

.swiper-slider {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  transition: 0.7s;
  opacity: 0;

  @media screen and (max-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media screen and (max-width: 481px) {
    flex-direction: column-reverse;
    gap: 15px;
    justify-content: unset;
  }
}

.active {
  opacity: 1;
}

.image {
  position: relative;
  width: 100%;
  object-fit: contain;
  height: auto;
  object-position: center;
  border-radius: 12px;

  @media screen and (max-width: 1024px) {
    margin-bottom: -130px;
    width: unset;
    height: unset;
    max-width: 132px;
  }

  @media screen and (max-width: 481px) {
    margin-bottom: -110px;
  }
}

.image-ontop {
  position: absolute;
  right: 80px;
  top: 110px;
  max-width: 134px;
  max-height: 290px;
  border-radius: 12px;
  box-shadow: 5px 10px 50px rgba(#351aa1, 0.3);

  @media screen and (max-width: 1260px) {
    top: 130px;
    max-width: 95px;
    max-height: 200px;
  }

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: auto;
  }
}

.body {
}

.title {
  display: inline-block;
  text-align: center;
  max-width: 490px;
  margin-bottom: 8px;
  @include font-32-bold;
  color: $white;

  @media screen and (max-width: 1024px) {
    @include font-16-bold;
    text-align: start;
    max-width: 220px;
  }

  @media screen and (max-width: 481px) {
    text-align: center;
    max-width: 261px;
    margin-bottom: 0;
  }
}

.text {
  text-align: center;
  max-width: 340px;
  @include font-16;
  color: $white;

  @media screen and (max-width: 1024px) {
    @include font-14;
    text-align: start;
    max-width: 171px;
  }
}
