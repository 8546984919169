@import 'styles/helpers/variables.scss';

.wrapper {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background-image: url('/assets/img/Sources/bg-grad-system.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &--ru {
    background-image: url('/assets/img/Sources/bg-grad-system_ru.png');
  }
}

.logo {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  cursor: default;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    max-width: 530px;
    text-align: center;
    h1 {
      @include font-32-bold;
      margin: 0 0 16px 0;
    }

    span {
      @include font-16;
    }
  }

  &--ru {
    svg {
      margin-bottom: 32px;
    }
  }

  &__locale {
    svg {
      width: 82px;
      height: 54px;
      margin-bottom: 48px;
    }

    &--ru {
      svg {
        width: 200px;
        height: 34px;
        margin-bottom: 56px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    svg {
      width: 175px;
      height: 140px;
    }

    &__text {
      max-width: 400px;
      h1 {
        @include font-22-bold;
        margin: 0 0 8px 0;
      }

      span {
        @include font-14;
      }
    }

    &--ru {
      svg {
        width: 128px;
        height: 128px;
        margin-bottom: 24px;
      }
    }

    &__locale {
      svg {
        width: 58px;
        height: 38px;
      }

      &--ru {
        svg {
          width: 153px;
          height: 26px;
          margin-bottom: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__text {
      max-width: 288px;
    }

    &--ru {
      .content__text {
        max-width: 320px;
      }
    }
  }
}

.btn {
  margin-top: 32px;
}
