@import '/src/styles/helpers/variables.scss';

.wrapper {
  display: flex;
  gap: 8px;
  margin-top: 24px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 10px;
    overflow-x: auto;
  }
}

.filter {
  padding: 9px 12px 9px 16px;
  border: 1px solid var(--accent-second);
  border-radius: 20px;
  display: flex;
  gap: 8px;
  color: var(--text-color);
  cursor: pointer;
  white-space: nowrap;

    @media screen and (max-width: $xs) {
      padding: 6px 8px 6px 12px;
      gap: 4px;
      font-size: 12px;
    }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--accent-color);
  background-color: var(--light-gray);
  @include font-10-bold;
}

.active {
  background-color: var(--accent-color);
  color: $white;
  opacity: 1;
  border-color: var(--accent-color);

  .count {
    background-color: var(--light-gray);
    color: var(--accent-color);
  }
}
