@import 'styles/helpers/variables.scss';

.header {
  &__title {
    @include font-30-bold;
    margin-top: 0;
    margin-bottom: 12px;
    color: var(--text-color);

    @media (max-width: 1024px) {
      @include font-22-bold;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: var(--holder-color);
    margin-bottom: 24px;
    @include font-12;

    &_item {
      display: flex;
      align-items: center;
      gap: 6px;
      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: var(--accent-second);
      }
    }
  }

  &__type {
    @include font-12-bold;
    color: var(--accent-color);
  }

  &__id {
    font-weight: 500;
    color: var(--holder-color);
  }
}
