@import 'styles/main.scss';

.container {
  position: relative;
  width: 100%;
  height: 100dvh;
  background-color: var(--accent-color);
  overflow: hidden;

  .logo {
    position: absolute;
    width: fit-content;
    height: 28px;
    top: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    @media screen and (max-width: $xs) {
      top: 24px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .right {
    position: absolute;
    z-index: 0;
    top: -87px;
    right: -140px;
    width: 743px;
    height: 279px;
    animation: moveInRight 1.5s forwards;

    @media screen and (max-width: $sm) {
      width: 544px;
      height: 203px;
    }

    @media screen and (max-width: $xs) {
      width: 327px;
      height: 122px;
      top: 0px;
      right: 0px;
    }
  }

  .left {
    position: absolute;
    z-index: 0;
    bottom: -87px;
    left: -140px;
    width: 829px;
    height: 496px;
    animation: moveInLeft 1.5s forwards;

    @media screen and (max-width: $sm) {
      width: 652px;
      height: 391px;
    }

    @media screen and (max-width: $xs) {
      width: 365px;
      height: 218px;
      bottom: 0px;
      left: 0px;
    }
  }

  .form {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $xs) {
      width: calc(100% - 32px);
    }

    .title {
      color: var(--text-color);
      @include font-52-bold;
      text-align: center;
      text-wrap: nowrap;
      color: var(--accent-light);

      @media screen and (max-width: $sm) {
        @include font-44-bold;
      }

      @media screen and (max-width: $xs) {
        @include font-40-bold;
        text-wrap: balance;
      }
    }

    .description {
      text-align: center;
      color: var(--accent-light);
      text-wrap: nowrap;
      @include font-24;
      margin-top: 8px;

      @media screen and (max-width: $sm) {
        @include font-20;
      }

      @media screen and (max-width: $xs) {
        @include font-16;
        text-wrap: balance;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      gap: 16px;
      width: fit-content;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .button {
        height: 40px;
        width: fit-content;
        align-self: center;
        width: 100%;
      }

      .submit-button {
        color: $white;
        background-color: rgba(247, 147, 147, 1);
        box-shadow:
          0px 4.52px 37.68px 0px rgba(229, 130, 151, 0.1),
          0px 15px 77px 0px rgba(229, 130, 151, 0.1);
      }
    }
  }
}

.invitation {
  padding: 8px 20px;
  border-radius: 36px;
  @include font-14;
  color: $white;
  background-color: rgba(255, 255, 255, 0.3);
  margin-bottom: 42px;
  width: fit-content;
  align-self: center;
}