@import 'styles/helpers/variables.scss';

.materials {
  display: flex;
  width: 100%;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    &__title {
      display: flex;
      align-items: center;
      gap: 4px;
      @include font-14-bold;
      color: var(--text-color);

      @media (max-width: 768px) {
        @include font-14;
      }

      .end-icon__tooltip {
        margin-left: unset !important;
        font-weight: 400;
      }

      .infoIcon {
        display: flex;
      }
    }

    &__description {
      @include font-11-bold;
      color: var(--holder-color);

      @media (max-width: 768px) {
        @include font-11;
      }
    }
  }

  &__upload {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    button {
      background: none;
    }

    @media (max-width: $xs) {
      width: 100%;
      justify-content: space-between;
    }

    .error {
      .paperClipIcon {
        path {
          stroke: #ea4a70;
        }
      }

      .materials__upload__fileName {
        color: #ea4a70;
      }

      .errorText {
        font-size: 11px;
        color: #ea4a70;
        line-height: 130%;
      }
    }

    &__button {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      background: none;
      padding: 0;

      .paperClipIcon {
        path {
          stroke: var(--accent-color);
        }
      }
    }

    &__fileName {
      @include font-14-bold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__size {
        @include font-10-bold;
        color: var(--holder-color);
      }

      @media (max-width: 768px) {
        font-size: 14px;
        &__size {
          @include font-10;
        }
      }
    }

    .moreButtonWrapper {
      position: relative;

      .moreButton {
        padding: 10px;
        border-radius: 80px;

        &__dotIcon {
          display: flex;
          svg {
            path {
              stroke: var(--gray-color);
            }

            width: 18px;
            height: 18px;
          }
        }
      }

      .dropdown {
        position: absolute;
        background: #ffffff;
        display: flex;
        border: 1px solid var(--gray-second);
        flex-direction: column;
        padding: 6px;
        box-shadow: var(--cart-shadow);
        width: fit-content;
        border-radius: 20px;
        top: 35px;
        right: 0;
        z-index: 1;

        &__item {
          cursor: pointer;
          padding: 6px 12px;
          @include font-14;
          color: var(--text-color);
        }
      }
    }

    .removeIcon {
      margin-left: 20px;
      margin-right: -40px;
      cursor: pointer;

      path {
        stroke: #c4bbd2;
      }
    }
  }
}
