.filter {
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__item {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.checkbox {
  cursor: pointer;

  svg {
    width: 11px !important;
    height: 9px !important;
  }

  span {
    &:last-child {
      display: inline-block;
      margin-left: 8px;
      font-size: 13px;
    }
  }
}
