@import 'styles/helpers/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  svg {
    height: 35px !important;
    width: 35px !important;
  }
}

.fake-input {
  position: absolute;
  z-index: 1;
  opacity: 0;
  height: 0;
  padding: 0;
  border: none;
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: $white;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 8px;

  .startIcon {
    display: flex;
    align-items: center;
  }

  .enter-source {
    position: relative;
    margin: 4px 4px 0;
    input {
      @include font-14;
      padding-left: 2px !important;
    }
  }

  .list {
    padding: 0;
    margin: 4px 0;
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-third);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-second);
    }
  }
}

.arrowIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 50%;
  z-index: 20;
  width: 13px;
  height: 8px;
  cursor: pointer;
  transform: scale(1.2);

  path {
    fill: var(--gray-color);
  }
}

.arrowActive {
  transform: translateY(-3px) scale(-1.2);
}
