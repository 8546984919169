@import '../../styles/main.scss';

.wrapper {
  padding: 40px 0 0;

  @media screen and (max-width: 960px) {
    padding: 54px 0;
  }
}

.back {
  display: flex;
  gap: 8px;
  align-items: center;
  border: none;
  @include font-12-bold;
  background: transparent;
  color: var(--accent-color);
  svg {
    fill: var(--accent-color);
  }
}

.title {
  margin: 32px 0;
  @include font-32-bold;

  @media screen and (max-width: 1024px) {
    margin: 24px 0;
    @include font-32-bold;
  }
}

.text {
  max-width: 856px;
  margin: 0 0 16px;
  @include font-18;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    @include font-14;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 795px;
  @include font-18;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    @include font-14;
  }
}

.list-item {
  position: relative;
  padding-left: 28px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 7px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--base-gradient);
  }

  @media screen and (max-width: 1024px) {
    padding-left: 18px;
  }
}
