@import '/src/styles/helpers/variables.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 40px;

  .errorMessage {
    color: $red-middle;
    @include font-12();
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;

  div {
    input {
      color: rgba(37, 37, 41, 0.5);
      cursor: default !important;
    }
  }
}

.main-input {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 4px;
  padding-right: 10px;
  border-radius: 8px;
  border: 1px solid #e4e4e5;
  background: #f7f7fa;

  &.error {
    border-color: $red-middle;
    background: $red-light;

    input:hover {
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  .drop {
    width: 100%;

    input {
      background-color: transparent;
      border: none;
      cursor: pointer;
      opacity: 1 !important;
      @include font-14;

      @media (max-width: 1024px) {
        padding: 8px 8px 6px 12px;
      }

      &:hover {
        border-color: var(--text-light);
        background-color: var(--light-gray);
      }

      &:focus {
        border-color: var(--text-light);
        background-color: var(--light-gray);
      }
    }
  }

  .arrow {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 2px;
    cursor: pointer;

    svg {
      width: 13px;
      height: 8px;
    }
  }

  .arrowActive {
    margin: 0;
    transform: rotate(180deg);
  }
}

.dropActive {
  border-color: var(--accent-color);
}

.dropdown {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: $white;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  border-radius: 8px;

  .enter-source {
    position: relative;
    margin: 16px 16px 0;
  }

  .list {
    padding: 0;
    margin: 4px 0;
    max-height: 150px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--accent-light);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-color);
    }

    .item {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 9px 16px;
      @include font-14;
      color: var(--text-color);
      cursor: pointer;
      transition: background 0.3s linear;
      margin: 0 4px;
      border-radius: 4px;
      span {
        color: var(--holder-color);
      }
      &:hover {
        background: var(--accent-light);
      }
      @media (max-width: 1024px) {
        padding: 9px 10px;
        margin: 0;
      }
    }

    .active {
      color: var(--accent-color);
    }
  }
}

.textTheme {
  .main-input {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;

    &__text {
      margin-right: 4px;
      cursor: pointer;
    }
  }

  .dropdown {
    margin-top: 9px;
    width: max-content;
    right: 0;

    .item {
      min-width: 92px;
      padding: 4px 8px;
      svg {
        display: none;
      }
    }
  }

  .arrow {
    margin-top: 7px;
    &.arrowActive {
      margin-top: 1px;
    }
  }
}

.selectLang {
  .main-input {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }
  input {
    border: none;
    background: transparent;
    width: 43px;
    padding-left: 10px;
    padding-right: 0;

    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    @media screen and (max-width: 1024px) {
      color: $white;
    }
  }

  .arrow {
    svg {
      path {
        fill: var(--text-color);

        @media screen and (max-width: 1024px) {
          fill: $white;
        }
      }
    }
  }

  .arrowActive {
    margin-bottom: 2px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 4px;
    }
  }

  .dropdown {
    right: 0;
    width: 144px;

    .list {
      margin: 8px 0;
    }
  }
}
