//Grid variables

$xxs: 360px;
$xs: 768px;
$xs-up: 961px;
$sm: 1024px;
$md: 1280px;
$lg: 1440px;
$xl: 1920px;
$xxl: 2880px;
$extra: 3840px;

.gridContainer,
.gridItem {
  width: 100%;
}

.container {
  &--wrap {
    flex-wrap: wrap;
  }

  &--sb {
    justify-content: space-between;
  }

  &--sa {
    justify-content: space-around;
  }

  &-center {
    justify-content: center;
  }
}

.direction-row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;

  @media screen and (min-width: $lg) {
    grid-column-gap: 20px;
  }

  @media screen and (min-width: $xxl) {
    grid-column-gap: 48px;
  }

  @media screen and (min-width: $extra) {
    grid-column-gap: 64px;
  }
}

.fwrap {
  &-wrap {
    flex-wrap: wrap;
  }

  &-nowrap {
    flex-wrap: nowrap;
  }
}

.pl {
  &-15 {
    padding-left: 15px;
  }

  &-20 {
    padding-left: 20px;
  }
}

.pr {
  &-15 {
    padding-right: 15px;
  }

  &-20 {
    padding-right: 20px;
  }
}

.ml {
  &-15 {
    margin-left: 15px;
  }

  &-20 {
    margin-left: 20px;
  }
}

.mr {
  &-15 {
    margin-right: 15px;
  }

  &-20 {
    margin-right: 20px;
  }
}

.d {
  display: flex;

  &-row {
    flex-direction: row;
  }
}

.jc {
  &-space-between {
    justify-content: space-between;
  }

  &-flex-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-space-around {
    justify-content: space-around;
  }
}

.ai {
  &-center {
    align-items: center;
  }

  &-flex-start {
    align-items: flex-start;
  }

  &-flex-end {
    align-items: flex-end;
  }

  &-stretch {
    align-items: stretch;
  }
}

// Breakpoints

.default {
  @media screen and (min-width: 0px) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.xxs {
  @media screen and (min-width: $xxs) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.xs {
  @media screen and (min-width: $xs) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.xsUp {
  @media screen and (min-width: $xs-up) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.sm {
  @media screen and (min-width: $sm) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.md {
  @media screen and (min-width: $md) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.lg {
  @media screen and (min-width: $lg) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.xl {
  @media screen and (min-width: $xl) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.xxl {
  @media screen and (min-width: $xxl) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}

.extra {
  @media screen and (min-width: $extra) {
    @for $i from 1 to 13 {
      &-#{$i} {
        grid-column: span $i;
      }
    }
  }
}
