@import 'styles/main.scss';

.form {
  :global(.common__tooltip) {
    min-width: 150px !important;
  }
}

.top-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}

.email {
  grid-column-start: 1;
  grid-column-end: 3;
}

.bottom-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  column-gap: 16px;
  margin-bottom: 32px;
}

.submit {
  display: flex;
}

.button {
  margin-right: 24px;
}

.text {
  @include font-14;
  display: flex;
  max-width: 355px;
  flex-wrap: wrap;

  .link {
    a {
      color: var(--accent-color);
    }
  }
}

.step {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
  display: none;
}

.active {
  position: relative;
  opacity: 1;
  visibility: visible;
  transition: 0.1s;
  display: block;
}
