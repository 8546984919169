@import 'styles/helpers/variables.scss';

.inputWrap {
  position: relative;
  margin-bottom: 24px;

  .inputRight {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 8px;

    .selectRole {
      min-width: 150px;
    }

    .button {
      height: fit-content;

      @media (max-width: 1010px) and (min-width: $xs-up) {
        padding: 3px 20px;
        font-size: 13px;
      }
    }
  }
}

.inviteEmployee {
  position: fixed;
  bottom: 75px;
  width: calc(100% - 40px);
  z-index: 10;
}

.inner {
  min-width: 100%;
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: $xs-up) {
    padding: 40px;
  }

  .header {
    .title {
      @include font-22-bold;
      margin: 0;

      @media screen and (max-width: $xs) {
        @include font-18-bold;
      }
    }
  }

  .radioGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.input {
  @include font-14;
  input {
    padding-right: 260px;
  }
}