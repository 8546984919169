@import "styles/helpers/variables.scss";
.tiptap {
  @media (max-width: $xs-up) {
    font-size: 16px !important;
  }

  margin: 0 12px;
  height: 100%;

  strong {
    font-weight: var(--font-weight-bold);
  }

  h1 {
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    line-height: normal;
  }

  /* List styles */
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }
}