@import 'styles/helpers/variables.scss';

.languages{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.language{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 12px 12px 12px 16px;
    cursor: pointer;

    &__name{
        @include font-14;
    }

    &__selected{
        background-color: var(--accent-third);
        border-radius: 24px;
    }
}