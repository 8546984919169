@import 'styles/helpers/variables.scss';
.wrapper {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .info {
      max-width: 100%;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--accent-color);
  padding: 16px;
  min-height: 168px;
  color: $white;
  max-width: 270px;

  span {
    display: block;
  }

  &__title {
    @include font-16-bold;
    margin-bottom: 4px;
  }

  button {
    display: flex;
    align-items: center;
    background-color: $white;
    color: var(--accent-color);
    @include font-14-bold;
    gap: 8px;
    padding: 6px 24px;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent-color);
    }
  }
}

.paylist {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    button {
      width: 100%;
      @include font-16-bold;
      display: flex;
      padding: 13px 54px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      background: var(--accent-light);
      color: var(--accent-color);
    }
  }
}
