.tag {
  color: var(--accent-color);
  padding: 9px 12px 7px;
  background-color: var(--accent-light);
  border-radius: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $xs) {
    padding: 6px 10px 4px;
  }

  &__text {
    @include font-12-bold;
    text-transform: uppercase;
    position: relative;
    top: 1px;

    @media screen and (max-width: $xs) {
      @include font-10-bold-upper;
    }
  }

  &--red {
    background-color: #f96d58;

    .tag__text {
      color: $white;
    }
  }

  &--green {
    background-color: $green;

    .tag__text {
      color: $white;
    }
  }

  &--purple {
    background-color: var(--accent-color);

    .tag__text {
      color: $white;
    }
  }

  &--blue {
    background-color: $blue-light;

    .tag__text {
      color: $white;
    }
  }

  &--small {
    padding: 5px 10px 5px;

    .tag__text {
      @include font-10-bold-upper;
    }
  }

  &__icon {
    margin-right: 7px;
  }
}
