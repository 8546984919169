@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 48px 110px 48px 40px;
  background: var(--accent-light);
  border-radius: 24px;
  margin: 48px 0;

  @media screen and (max-width: 1024px) {
    padding: 32px 20px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    @include font-14;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ul,
  ol {
    max-width: 600px;
    @include font-14;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  h1 {
    line-height: normal;
  }

  ul {
    li {
      display: flex;
      margin-bottom: 16px;

      &:before {
        content: '';
        display: block;
        background-image: var(--check-icon);
        width: 1.3em;
        margin-right: 16px;
        min-width: 1.3em;
        background-repeat: no-repeat;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: counter;

    li {
      display: flex;
      margin-bottom: 16px;
      counter-increment: counter;

      &:before {
        content: counter(counter) '.';
        color: var(--accent);
        font-size: 16px;
        margin-right: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.title {
  max-width: 708px;
  margin-bottom: 32px;
  @include font-22-bold;
  margin-top: 0;

  @media screen and (max-width: 1024px) {
    @include font-20-bold;
  }

  span {
    color: $blue;
  }
}

.element {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    gap: 8px;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  .text {
    max-width: 564px;
    @include font-14;

    @media screen and (max-width: 1024px) {
      max-width: 80%;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
