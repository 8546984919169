@import '/src/styles/helpers/variables.scss';

.goals-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;

  &__item {
    display: flex;
    gap: 8px;
    align-items: center;

    &_number {
      color: var(--holder-color);
      padding: 5px 8px;
      width: 24px;
      height: 24px;
      text-align: center;
      @include font-13-bold;
      border-radius: 50%;
      background: var(--accent-third);
    }

    &_label {
      color: var(--text-color);
    }
  }
}
