@import 'styles/main.scss';

.table {
  width: 100%;
  &__row {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;
    border-bottom: 1px solid var(--accent-light);

    @media (max-width: 1350px) {
      grid-template-columns: 30px 277px 270px 150px 180px 140px;
    }

    @media (max-width: 1150px) {
      grid-template-columns: 30px 190px 190px 150px 180px 140px;
    }

    &_mob {
      display: none;
    }

    &:first-child {
      @include font-12-bold;

      .table__cell {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 500;

        .container {
          flex-direction: column;
        }

        svg {
          width: 16px;
          height: 16px;
        }

        &:first-child {
          padding: 0;
        }
      }
    }
  }

  &.income {
    .table__row {
      grid-template-columns: 30px 300px 306px 306px 306px;

      @media screen and (max-width: 1350px) {
        grid-template-columns: 30px 3fr 2fr 2fr 1fr;
      }

      @media screen and (max-width: 1200px) {
        grid-template-columns: 30px 3fr 1fr 2fr 1fr;
      }
    }

    .table__content {
      grid-template-columns: 30px 300px 306px 306px 306px;

      @media screen and (max-width: 1350px) {
        grid-template-columns: 30px 3fr 2fr 2fr 1fr;
      }

      @media screen and (max-width: 1200px) {
        grid-template-columns: 30px 3fr 1fr 2fr 1fr;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    outline: none;
    color: var(--text-color);

    @media (max-width: $sm) {
      @include font-12;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 30px 277px 270px 223px 223px 223px;

    @media screen and (max-width: 1350px) {
      grid-template-columns: 30px 277px 270px 150px 180px 140px;
    }

    @media (max-width: 1150px) {
      grid-template-columns: 30px 190px 190px 150px 180px 140px;
    }
  }

  &__cell {
    padding: 12px 16px;

    &:first-child {
      padding: 12px 8px;
      color: var(--holder-color);
    }
  }

  &__search {
    padding: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--text-color);
    cursor: pointer;
    @include font-12;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      stroke: var(--accent-color);
    }
  }

  &__firstlink {
    color: var(--accent-color);
  }

  &__header {
    @include font-12;
    max-width: 270px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    @include font-11;
    color: var(--holder-color);
    max-width: 270px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button {
    background: var(--accent-color);
    color: $white;
    @include font-12-bold;
    border: none;
    padding: 6px 24px 6px 20px;
    border-radius: 80px;
    text-wrap: nowrap;

    &:disabled {
      opacity: 0.6;
      background: var(--light-gray);
      color: var(--text-light);
      cursor: default;
    }
  }

  @media screen and (max-width: 1150px) {
    &__header {
      max-width: 180px;
    }

    &__text {
      max-width: 180px;
    }
  }
}

.currency {
  font-family: $font-currency;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
