@import 'styles/main.scss';

.form {
  z-index: 1;
  background-color: $white;
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 508px;
  justify-self: center;
  margin-top: 25px;
  margin-bottom: 25px;

  @media (min-height: 750px) {
    align-self: center;
  }

  @media (max-height: 750px) {
    align-self: start;
  }

  @media screen and (max-width: $xs) {
    padding: 24px 20px;
    width: calc(100% - 32px);
  }

  :global(.common__tooltip) {
    min-width: 150px !important;
  }

  .content {
    overflow-y: auto;
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    .headerTitle {
      @include font-28-bold;
      margin: 0;
      text-align: center;
      color: var(--text-color);
    }

    .headerDescription {
      margin: 0;
      text-align: center;
      @include font-14;
      color: var(--text-color);
    }
  }

  .top-fields {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    column-gap: 16px;
    margin-bottom: 20px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }
  }

  .email {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .goals {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      @include font-12-bold;
    }
  }

  .check {
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }
  }

  .bottom-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      column-gap: 16px;
    }
  }

  .submit {
    display: flex;
    align-items: center;
    max-height: 40px;
  }

  .submit-button {
    display: inline-flex;
    height: 40px;
    width: 100%;
  }

  .button {
    &:disabled {
      background: var(--switcher-active-bg);
      color: var(--base-white);
    }

    svg {
      fill: var(--accent-color);
    }
  }

  .text {
    @include font-12;
    display: flex;
    max-width: 360px;
    flex-wrap: wrap;

    span {
      margin-right: 4px;
      color: var(--text-color);
    }

    .link {
      margin-right: 4px;

      a {
        color: var(--accent-color);
      }
    }
  }

  .tabs {
    width: 100%;
    margin-bottom: 16px;
  }

  .comment {
    margin-bottom: 28px;
  }

  .input-field {
    position: relative;
    @include font-12-bold;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &--full {
      height: fit-content;
    }

    .input {
      min-height: 48px;
      width: 100%;
      padding: 4px 16px;
      border-radius: 8px;
      border: 1px solid var(--accent-second);
      @include font-14;
      background: var(--base-white);
      color: var(--text-color);
      transition: 0.3s;

      input {
        padding-left: 2px;
      }

      &.disabled {
        input {
          caret-color: transparent;
        }
      }

      span {
        font-size: 11px;
        line-height: 130%;
      }

      &:hover {
        background: var(--base-white);
        border-color: var(--accent-color);
      }

      &__focused {
        background: var(--accent-light) !important;
        border-color: var(--accent-color) !important;
      }
    }
  }

  .error {
    position: absolute;
    color: $red;
    width: fit-content;
    right: 12px;
    white-space: nowrap;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 10;
    height: 18px;

    @media screen and (max-width: 1024px) {
      bottom: calc(1em - 4px);
    }
  }

  .captcha {
    position: relative;
    margin-top: 32px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
      margin-top: 28px;
      margin-bottom: 22px;
    }
  }

  .checkbox-block {
    margin-top: 12px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.mainInput {
  border: 1px solid var(--accent-second);
  background: var(--base-white);
  padding: 12px 11px 12px 16px;
  min-height: 48px;

  span {
    padding-left: 2px;
  }

  &:hover {
    border-color: var(--accent-color);
  }
}

.mainInputExpanded {
  background: var(--accent-light) !important;
  border-color: var(--accent-color) !important;
}

.fakeInput {
  z-index: -1;
}

.multiselectWrapper {
  min-height: 48px;
  cursor: pointer;
}

.multiselect {
  &__focused {
    background: var(--accent-light) !important;
    border-color: var(--accent-color) !important;
  }
}
