.customTooltip {
  border-radius: 12px;
  min-width: 290px;
  max-width: 340px;
}

.introjs-tooltip {
  width: 270px;
}

.customTooltip .introjs-tooltip-header {
  padding: 20px 30px 0px 20px;
}

.customTooltip .introjs-tooltip-title {
  color: #323236;
  font-family: 'Grato Grotesk';
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
}

.customTooltip .introjs-tooltiptext {
  padding: 12px 20px;
  color: #323236;
  font-family: 'Grato Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.customTooltip .introjs-button {
  background-color: #eee;
  border: 1px solid #616161;
  border-radius: 8px;
  color: #212121;
  font-family: 'Grato Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 8px 16px;
}

.customTooltip .introjs-button:focus {
  outline: none;
  box-shadow: none; 
}

.customTooltip .introjs-disabled {
  background-color: #f4f4f4;
  background-image: none;
  border-color: #bdbdbd;
  box-shadow: none;
  color: #9e9e9e;
}

.introjs-helperLayer {
  border-radius: 12px;
  box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px;
}
