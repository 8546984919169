@import 'styles/main.scss';

.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .logo {
    position: absolute;
    width: 60px;
    height: 40px;
    top: 48px;
    left: 48px;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      width: 40px;
      height: 26px;
      top: 16px;
      left: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 43%;
  background: linear-gradient(183.88deg, #65b2ff 1.64%, #6638f4 96.82%);
  padding: 141px 48px 54px 48px;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &--title {
      color: $white;
      @include font-18;
      width: 100%;
    }

    &--text {
      color: $white;
      width: 100%;
      @include font-30-bold;
    }

    @media screen and (max-width: 1024px) {
      gap: 8px;
      min-width: 211px;
      max-width: 350px;

      &--title {
        @include font-14;
      }

      &--text {
        @include font-16-bold;
        text-align: start;
      }
    }

    @media screen and (max-width: 350px) {
      &--title {
        text-align: start;
      }

      &--text {
        @include font-14-bold;
        text-align: start;
      }
    }
  }

  &-images {
    position: relative;
    width: 100%;
    height: max-content;
    max-width: 699px;
    max-height: 440px;
    min-width: 239px;
    min-height: 155px;

    .image {
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 12px;
    }

    .image-ontop {
      position: absolute;
      border-radius: 12px;
      max-width: 237px;
      max-height: 385px;
      box-shadow: -33px 33px 100px rgb(28, 20, 37, 0.35);
      top: -35px;
      right: -20px;
    }

    @media screen and (max-width: 1600px) {
      .image-ontop {
        max-width: 137px;
        max-height: 285px;
        right: -15px;
      }
    }

    @media screen and (max-width: 1440px) {
      max-width: 499px;
      max-height: 330px;
    }

    @media screen and (max-width: 1260px) {
      .image-ontop {
        max-width: 105px;
        max-height: 220px;
        top: -20px;
        right: -10px;
      }
    }

    @media screen and (max-width: 1024px) {
      max-width: 399px;
      max-height: 230px;
      margin-top: unset;
      margin-left: auto;
      transform: translateX(50%) translateY(35%);

      .image-ontop {
        max-width: 81px;
        max-height: 187px;
        box-shadow: 23px 23px 75px rgb(28, 20, 37, 0.35);
        left: -10px;
        top: -80px;
      }
    }

    @media screen and (max-width: 481px) {
      transform: translateX(20%) translateY(35%);
    }
  }

  .success {
    object-fit: contain;
    width: 441px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media screen and (max-width: 1024px) {
      width: 246px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 288px;
    flex-direction: row;
    justify-content: unset;
    padding: 76px 16px 0px 16px;
  }
}

.right {
  position: relative;
  width: 57%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 156px;

  @media screen and (max-width: 1270px) {
    padding: 0 80px;
  }

  @media screen and (max-width: 1024px) {
    position: static;
    z-index: 11;
    width: 100%;
    padding: 24px 16px;
    margin-top: -27px;
    border-radius: 28px 28px 0px 0px;
    background-color: $white;
  }

  .title {
    color: var(--text-color);
    margin-bottom: 4px;
    @include font-32-bold;

    @media screen and (max-width: 1024px) {
      @include font-24-bold;
    }
  }

  .text {
    @include font-16;
    margin-bottom: 32px;
    color: var(--text-color);

    span {
      display: flex;
    }

    .link {
      margin-left: 8px;
      @include font-16-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}

.word-logo {
  width: 152px;
  margin-left: 4px;

  @media screen and (max-width: 1024px) {
    width: 114px;
  }
}

.form {
  .fields {
    display: grid;
    row-gap: 32px;
  }

  .input-field {
    position: relative;
    .item-label {
      @include font-12-bold;
      margin-bottom: 4px;
      color: var(--text-color);
    }

    input {
      height: 38px;
      background-color: var(--accent-light);
      // border: 1px solid #d8cfe6 !important;
      &:hover {
        background-color: #ffffff;
      }
      &::placeholder {
        color: var(--holder-color);
      }
    }

    .password {
      position: absolute;
      right: 0;
      top: 0;
      @include font-12-bold;

      a {
        color: var(--accent-color);
      }
    }

    .svg {
      fill: var(--text-light);
    }
  }

  .check {
    margin-top: 20px;
    width: fit-content;
  }

  .button {
    height: 40px;

    &.disabledCaptcha {
      margin-top: 36px;
    }

    &:disabled {
      background: var(--accent-third);
      color: var(--text-light);
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 22px;
  bottom: 4px;
  z-index: 10;
}

.captcha {
  position: relative;
  margin-top: 36px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}

.mobile-error {
  background: linear-gradient(
      0deg,
      rgba(245, 34, 45, 0.04),
      rgba(245, 34, 45, 0.04)
    ),
    #ffffff;
  border: 1px solid $red;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 40px;
  padding: 8px 16px;

  @media screen and (max-width: 1024px) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
}

.show-password {
  position: absolute;
  right: 13px;
  bottom: 6px;
  z-index: 10;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    bottom: 5px;
  }
}

.lang {
  position: absolute;
  top: 48px;
  right: 48px;
  height: 30px;

  @media screen and (max-width: 1024px) {
    top: 16px;
    right: 16px;
  }
}

.invitation {
  padding: 4px 12px;
  border-radius: 36px;
  @include font-11-bold;
  color: var(--accent-color);
  background-color: var(--accent-light);
  margin-bottom: 20px;
  width: fit-content;
}
