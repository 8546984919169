.footer {
  padding-top: 20px;
  border-top: 1px solid rgba(160, 156, 172, 0.7);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $xs) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__copy {
    color: var(--text-light);
    @include font-12;
  }

  &__menu {
    display: flex;
    align-items: center;

    &-item {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      color: var(--text-light);
      transition: $transition;
      @include font-12;

      &:hover {
        color: var(--text-color);
      }
    }

    @media screen and (max-width: $xs) {
      margin-bottom: 12px;
    }
  }
}
