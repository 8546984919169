@import '/src/styles/helpers/variables.scss';

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
  max-width: 500px;
  margin: auto;
}

.text {
  margin: 0;
}

.title {
  margin-bottom: 16px;
  padding: 0 32px;
  box-sizing: content-box;
  text-align: center;

  @media screen and (max-width: $xs-up) {
    margin-bottom: 12px;

    h2 {
      font-size: 22px;
    }
  }
}

.subtitle {
  margin-bottom: 40px;
  text-align: center;
  @media screen and (max-width: $xs-up) {
    padding: 0 32px;
  }
  h3 {
    font-size: 16px;
  }
}

.wrap {
  margin-top: 87px;

  &:after {
    content: '';
    display: block;
    width: 100vw;
    height: calc(100vh - 60px);
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('/assets/img/System/gradient.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $xs-up) {
      height: calc(100vh - 56px);
      background-image: url('/assets/img/System/gradient-mobile.png');
    }
  }

  @media screen and (max-width: $xs-up) {
    margin-top: 150px;
  }
}

.button {
  color: $white;
}

.img {
  position: fixed;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translate(-50%) rotate(9deg);
  display: flex;
  justify-content: center;
  width: 697px;
  height: 418px;

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: $xs-up) {
    width: 480px;
    height: 287px;
    margin: auto;
    bottom: 10px;

    img {
      max-width: 100%;
    }
  }
}
