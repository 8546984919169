@import 'styles/helpers/variables.scss';

.formlabel {
  width: 100%;
  color: #252529;
  display: flex;
  @include font-12-bold;
  margin-bottom: 4px;
}

.formlabel.left {
  justify-content: flex-start;
}
.formlabel.right {
  justify-content: flex-end;
}
