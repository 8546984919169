@import '../../../styles/main.scss';

.formhint {
  width: 100%;
  color: var(--text-color);
  display: flex;
  @include font-14-bold;
  margin-top: 4px;
}
.formhint.left {
  justify-content: flex-start;
}
.formhint.right {
  justify-content: flex-end;
}

.input {
  width: 100%;
  padding: 11px 12px 11px 12px;
  border-radius: 8px;
  border: 1px solid var(--accent-second);
  @include font-14;
  background: var(--accent-light);
  color: var(--text-color);
  transition: 0.3s;
  &::placeholder,
  input::placeholder {
    color: #9c93aa;
  }

  @media screen and (max-width: $xs) {
    @include font-16;
  }

  &.accountForm {
    padding: 8px 12px;
    background: var(--accent-light);

    &:hover {
      background: var(--accent-light);
      border-color: var(--accent-color);
    }

    &.focused {
      background: var(--base-white);
    }

    &:disabled:hover {
      background: var(--accent-light);
      border-color: var(--accent-color);
    }

    &::placeholder {
      color: var(--holder-color);
    }
  }
}

.textarea {
  background: var(--accent-color);
  border: 1px solid var(--fire-color);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 14px;
  padding: 8px 12px;
  transition: 0.3s;
  width: 100%;
  min-height: 72px;
  &::placeholder {
    color: var(--tertiary-color);
  }
}

.inputWithStartIcon {
  padding: 8px 0 0 30px;
}

.inputicon,
.formtextarea.icon {
  padding-left: 40px;
}

.inputWrap,
.formtextareaWrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputInner,
.formtextareaInner {
  position: relative;
}

.input:disabled,
.formtextarea:disabled {
  cursor: default;
  opacity: 0.5;
}

.input:disabled:hover,
.formtextarea:disabled:hover {
  border-color: var(--text-light);
}

.input:hover,
.form__textarea:hover,
.input:focus,
.form__textarea:focus {
  border-color: var(--accent-color);
  background-color: $white;
}

.input::placeholder,
.form__textarea::placeholder {
  color: var(--tertiary-color);
}

.inputError,
.textareaError {
  border-color: var(--error-color) !important;
  padding-right: 43px;
  background-color: var(--accent-light) !important;
}

.textarea {
  resize: none;
}

.iconWrap {
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
