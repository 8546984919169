@import 'styles/main.scss';

.wrapper {
}

.heading {
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
  @include font-22-bold;
  color: var(--text-color);

  span {
    max-width: 416px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 20px;
  }

  svg {
    flex-shrink: 0;
    width: 68px;
    height: 53px;
  }
}

.body {
  padding: 32px;
  background: var(--light-gray);
  border-radius: 8px;

  @media screen and (max-width: 1024px) {
    padding: 24px;
  }

  .title {
    @include font-24-bold;
    margin-bottom: 24px;
    color: var(--text-color);

    @media screen and (max-width: 1024px) {
      @include font-20-bold;
      margin-bottom: 16px;
    }
  }

  .text {
    @include font-16;
    margin-bottom: 12px;
    color: var(--text-color);

    span {
      @include font-16-bold;
    }
  }

  .help {
    @include font-16;
    color: var(--text-color);

    .link {
      margin-left: 3px;
      color: var(--accent-color);
      cursor: pointer;
      @include font-16-bold;

      a {
        color: var(--accent-color);
      }
    }
  }
}
.notice {
  position: absolute;
  top: 10px;
  right: 10px;
}
