@import "styles/helpers/variables.scss";

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  @media screen and (max-width: $xs) {
    padding: 0 20px !important;
  }
}
