@import '/src/styles/helpers/variables.scss';

.menu {
  display: flex;
  align-items: center;
  gap: 26px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: start;
  }
}

.item {
  display: flex;
  align-items: center;
}

.link {
  position: relative;
  @include font-14;
  color: var(--text-color);
  transition: $transition;

  &:hover {
    color: var(--accent-color);
  }

  @media screen and (max-width: 1024px) {
    @include font-18;
  }
}

.linkWrap {
  display: flex;
  align-items: center;
  gap: 13px;

  .link {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      width: 4px;
      height: 4px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      background-color: var(--accent-color);
      transition: $transition;
      opacity: 0;
    }

    &:hover,
    &.active {
      &:after {
        opacity: 1;
      }
    }
  }

  &:hover,
  &.active {
    .link:after {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    .link:after {
      content: none;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .link:after {
      content: none;
    }
  }
}

.projects {
  display: flex;
}

// .mobile {
//   @media screen and (max-width: 1024px) {
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     max-width: calc(100%/5);
//     margin-right: 0;
//     z-index: 2;
//     padding-bottom: 5px;

//     .linkWrap:not(.active) {
//       svg {
//         path {
//           fill: rgba(160, 156, 172, 0.64);
//         }
//       }
//     }

//     &.dash {
//       left: 0;
//     }

//     &.market {
//       left: 0;
//     }

//     .wrap {
//       margin-top: 1px;
//     }

//     .linkWrap {
//       flex-direction: column;
//     }

//     .link {
//       font-size: 11px;
//       line-height: 130%;
//     }
//   }
// }

// .inside {

//   @media screen and (max-width: 1024px) {
//     position: absolute;
//     left: 0;
//     z-index: 4;
//     display: none;

//     &.project {
//       top: 62px;

//       &.active {
//         display: block;
//       }
//     }

//     &.finances {
//       top: 114px;

//       &.active {
//         display: block;
//       }
//     }

//     &.active {

//       .active {
//         .link {
//           color: var(--accent-color);
//         }
//       }
//     }

//     .link {
//       font-size: 20px;
//       line-height: 130%;
//     }
//   }
// }
