@import '/src/styles/helpers/variables.scss';

.wrapper {
  padding: 40px 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 54px 0;
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;

  &__top--center {
    left: 50% !important;
  }
}

.breadcrumbs {
  display: flex;
  margin-bottom: 24px;
  @include font-14;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 6px;

    &::after {
      content: '';
      width: 3px;
      height: 3px;
      margin-left: 6px;
      background: var(--text-color);
      border-radius: 50%;
    }

    &:last-child {
      .breadcrumbs__link {
        color: var(--holder-color);
      }

      &::after {
        display: none;
      }
    }
  }

  &__link {
    color: inherit;
    color: var(--text-color);
  }
}

.project {
  &__header {
    @include font-30-bold;
    margin-bottom: 12px;
    color: var(--text-color);
  }

  &__tabs {
    display: inline-flex;
    padding: 4px;
    border-radius: 20px;
    background: var(--accent-light);
    @include font-14;
    margin-bottom: 24px;
  }

  &__tab {
    cursor: pointer;
    color: var(--text-color);

    div {
      border-radius: 36px;
      padding: 6px 16px 5px 16px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      span {
        margin-left: 6px;
        color: var(--accent-second);
      }
    }

    input {
      display: none;
    }

    input:checked + div {
      box-shadow: 0px 3px 8px 0px rgba(201, 180, 250, 0.13);
      background: $white;
      color: var(--accent-color);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
    color: var(--accent-color);
    margin-bottom: 24px;
    @include font-12;

    &_item {
      display: flex;
      align-items: center;
      gap: 6px;
      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100px;
        background-color: var(--accent-second);
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }

    a {
      color: var(--accent-color);
    }

    .arrowIcon {
      width: 9px;
      height: 9px;
      margin-left: 5px;
      stroke-width: 1.5px;
    }
  }

  &__id {
    font-weight: 500;
    color: var(--holder-color);
  }
}

.grid {
  display: flex;
  gap: 20px;

  &__main {
    width: 100%;
    height: max-content;
    padding: 24px 24px 40px 24px;
    border-radius: 24px;
    background: $white;
    z-index: 1;
    box-shadow: var(--card-shadow);
  }

  &__side {
    width: 400px;
    flex-shrink: 0;

    &__card {
      padding: 24px;
      border-radius: 24px;
      background: $white;
      box-shadow: var(--card-shadow);
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &__top {
        display: flex;
        align-items: center;
        gap: 12px;

        svg,
        img {
          width: 48px;
          height: 48px;
          flex-shrink: 0;
          object-fit: cover;
          border-radius: 50%;
        }

        p,
        h4 {
          margin: 0;
        }
      }

      &__header {
        @include font-22-bold;
        color: var(--text-color);

        @media (max-width: 1024px) {
          font-size: 22px !important;
        }

        &_mini {
          @include font-16-bold;
          color: var(--text-color);
        }
      }

      &__par {
        @include font-14;
        color: var(--holder-color);
      }

      &__btn {
        margin-top: 24px;
        padding: 12px 24px;
        width: 100%;
        @include font-14-bold;
      }

      &__icon_btn {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 80px;
        background: #fff;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.map {
  position: relative;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--accent-color);
    @include font-12-bold;
  }

  .step {
    width: 100%;
    position: relative;
    margin-bottom: 16px;
    margin-left: 0.5em;

    &__date {
      @include font-12;
      width: 85px;
    }

    &__block {
      margin-top: 1px;

      span {
        color: var(--text-color);
        @include font-14-bold;
      }

      p {
        margin: 0;
        margin-bottom: 6px;
        @include font-12;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .empty {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(var(--text-light), 0.2);
    }

    &_empty {
      &::after {
        content: '';
        opacity: 0.2;
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: calc(100% + 2px);
      position: absolute;
      top: 18px;
      left: 7.3em;
      background-color: var(--accent-color);
    }

    &:last-child {
      margin-bottom: 0px;

      &::after {
        content: none;
      }
    }

    .name {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      @include font-14-bold;

      span {
        padding-top: 3px;
      }
    }

    .past {
      color: var(--text-light);
    }
  }
}

@media (max-width: 1024px) {
  .breadcrumbs {
    @include font-14;
  }

  .project {
    &__header {
      @include font-22-bold;
      margin-bottom: 4px;
    }
    &__top {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 24px;
    }

    &__id {
      margin-bottom: 0;
      @include font-12-bold;
      position: relative;
      display: flex;
      align-items: center;
    }
    &__link {
      padding-bottom: 20px;
      margin-bottom: 0;
      @include font-12;

      a {
        margin: 0;
      }
    }
    &__tabs {
      @include font-12;
      margin-bottom: 20px;
    }
  }

  .grid {
    flex-direction: column-reverse;
    gap: 16px;
    padding-bottom: 24px;
    margin: 0 -20px;
    &__side {
      border-radius: 24px;
      background: $white;
      padding: 20px;
      width: 100%;
      box-shadow: var(--card-shadow);
      &__card {
        margin-bottom: 12px;
        background: var(--accent-light);
        box-shadow: none;
        padding: 16px 20px;
        &:last-child {
          display: flex;
          justify-content: space-between;
        }
        &__header {
          font-size: 18px;
        }
        &__par {
          @include font-12;
        }
        &__btn {
          display: flex;
          align-items: center;
          width: 48px;
          height: 48px;
          padding: 0;
          background-color: $white;
          margin-top: 0;
          span {
            display: none;
          }
          svg {
            display: block;
          }
        }
      }
    }
    &__main {
      padding: 24px 20px;
      width: 100%;
    }
  }

  .map {
    .step {
      &__date {
        max-width: 53px;
        margin-right: 8px;
        flex-shrink: 0;
      }
      svg {
        flex-shrink: 0;
      }
      &::after {
        left: 5.55em;
      }
    }
  }
}

.empty {
  margin: 0;
}
