@import 'styles/helpers/variables.scss';

.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 0px 6px;
  @include font-14;

  &__title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-color);
    @include font-12;
  }
}
