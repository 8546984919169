@import '/src/styles/helpers/variables.scss';

.header {
  position: relative;
  z-index: 90;
  display: flex;
  gap: 52px;
  max-width: 1344px;
  margin: 24px auto 0;
  padding: 12px 24px;
  border-radius: 16px;
  box-shadow: var(--top-shadow);
  background: #ffffff;

  @media screen and (max-width: 1350px) {
    margin: 24px 20px 0;
  }

  @media screen and (max-width: 1024px) {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0;
    box-shadow: none;
    background: transparent;

    &.active {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      flex-direction: column;
      justify-content: start;
      width: 100%;
      height: 100vh;
      padding: 20px;
      background-color: #fff;

      .header__nav {
        padding-top: 20px;
      }
    }
  }

  @media (min-width: 2000px) {
    max-width: 1610px;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
      z-index: 100;
      padding-top: 8px;
      padding-bottom: 8px;
      padding: 8px 24px;
      border-radius: 26px;
      box-shadow: var(--card-shadow);
      background: #ffffff;
    }
  }

  &__activation {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__search-icon {
    display: flex;

    @media screen and (min-width: 1024px) {
      visibility: hidden;
      width: 0;
    }
  }

  &__nav {
    position: relative;
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: start;
      gap: 0;
      height: 85%;
    }
  }

  &__block {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 24px;
    width: 100%;
  }

  &__search {
    width: 100%;
    max-width: 425px;
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__list-item {
    position: relative;
  }

  &__list-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    cursor: pointer !important;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 4px;
      height: 4px;
      bottom: -8px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      background-color: var(--accent-color);
      opacity: 0;
      transition: $transition;
    }

    &:hover,
    &.active {
      &:after {
//        opacity: 1;
      }
    }

    @media screen and (max-width: 1024px) {
      height: unset;

      &:after {
        content: none;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;

      &:after {
        content: none;
      }
    }
  }

  &__list-item-wrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: $sm) {
      flex-direction: column;
    }
  }

  &__counter {
    position: relative;
    display: flex;
  }

  &__counter-count {
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding-right: 1px;
    border-radius: 50%;
    @include font-12-bold;
    color: $white;
    background-color: var(--accent-color);

    span {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }

  &__icon-wrap {
    margin-left: 13px;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }

  &__icon-text {
    position: relative;
    top: 2px;
    color: var(--text-color);
    @include font-14;
    transition: $transition;

    &:hover {
      color: var(--accent-color);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      width: 4px;
      height: 4px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      background-color: var(--accent-color);
      transition: $transition;
      opacity: 0;
    }

    &:hover,
    &.active {
      &:after {
        opacity: 1;
      }
    }

    @media screen and (max-width: 1024px) {
      font-size: 11px;
      line-height: 130%;
    }

    @media screen and (max-width: 1024px) {
      &:after {
        content: none;
      }
    }
  }

  &__deal {
    .header__list-link:after {
      content: none;
    }
  }

  &__globus {
    cursor: pointer;

    .modal {
      cursor: auto;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        .modal {
          visibility: visible;
        }

        .modalListGlobus {
          opacity: 1;
        }
      }
    }
  }

  &__burger {
    visibility: hidden;
    width: 0;

    button {
      padding: 0;
    }

    @media screen and (max-width: 1024px) {
      visibility: visible;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 20px;
    }
  }

  &__user {
    @media screen and (min-width: 1024px) {
      &:not(.disabled) {
        &:hover {
          .modal {
            visibility: visible;
          }

          .modalList {
            opacity: 1;
          }
        }
      }
    }
  }

  &__admin-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
  }

  &__admin-list-item {
    width: max-content;
  }

  &__bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.burger-footer {
  padding-top: 20px;
  padding-bottom: 30px;

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    background-color: #fff;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__copy {
    margin-top: 12px;
    color: var(--text-light);
    @include font-12;
  }
}

.bottom-menu {
  width: 100%;
  height: 56px;
  border-radius: 24px 24px 0 0;
  box-shadow: var(--card-shadow);
  background: #ffffff;

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 0 70px;
    margin: 0 auto;

    @media screen and (max-width: 576px) {
      padding: 0 10px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;

    &--user {
      svg {
        path {
          fill: rgba(var(--main-rgb), 0.7);
        }
      }
    }

    &:not(.active) {
      svg {
        filter: grayscale(1);

        path {
          fill: rgba(160, 156, 172, 0.64);
        }
      }
    }
  }

  &__item-text {
    margin-top: 2px;
    font-size: 11px;
    color: #252529;
  }
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: auto;
}

.active {
  .link {
    &:after {
      opacity: 1;
    }
  }
}

.search {
  padding-left: 16px;
  border-left: 1px solid var(--accent-light);
  padding-right: 22px;

  @media screen and (max-width: 1024px) {
    padding-left: 0;
    border: none;
    padding-right: 10px;
  }
}

.modal {
  position: absolute;
  right: -20px;
  top: 24px;
  z-index: 200;
  padding: 10px 0 0;
  visibility: hidden;
}

.modalList {
  width: 215px;
  padding: 15px 19px;
  border-radius: 8px;
  background: $white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.06));
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.modalListGlobus {
  padding: 15px 19px;
  border-radius: 8px;
  background: $white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.06));
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.modalItem {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modalLink {
  color: var(--text-color);
  cursor: pointer;

  &:hover {
    .modalName {
      color: var(--accent-color);
    }
  }
}

.modalInner {
  display: flex;
  align-items: center;
}

.modalInnerColumn {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.modalIcon {
  width: 24px;
  display: flex;
  justify-content: center;
  margin-right: 10px;

  @media screen and (max-width: 1024px) {
    justify-content: start;
  }
}

.modalName {
  color: var(--text-color);
  @include font-14;
  transition: $transition;
  position: relative;
  top: 2px;
}

.modalNameHighlight {
  color: var(--text-color);
  @include font-14-bold;
  transition: $transition;
  position: relative;
  top: 2px;
}

.modalButton {
  padding: 0;

  &:hover {
    .modalName {
      color: var(--accent-color);
    }
  }
}

.modalActive {
  display: block;
  position: fixed;
  opacity: 1;
  left: 15px;
  right: auto;
  top: auto;
  padding: 0;
  bottom: 40px;
  filter: none;
  transform: translateY(-100%);
  visibility: visible;

  .modalList {
    padding: 0 0 5px;
    filter: none;
    background: transparent;
    opacity: 1;
  }
}

.modalLinkActive {
  .modalName {
    color: var(--accent-color);
  }
}

.cart {
  display: block;
}
