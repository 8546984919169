.selectBox {
  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 6px;

    .main-input {
      border: 1px solid transparent !important;
      outline: none !important;


      &.dropActive {
        background: transparent !important;
      }

      &.small {
        height: fit-content !important;
        min-height: 0 !important;
        padding: 0 !important;
        background-color: transparent !important;
        width: 100%;
        justify-content: space-between;

        &:hover {
          border-color: transparent !important;
        }
      }

      &.inInput {
        transition: all 0.3s ease-in-out;

        min-height: 53px !important;
        height: 53px !important;
        padding: 6px 12px;
        background: transparent !important;

        &:hover {
          border: 1px solid transparent !important;
        }
      }

      &-focused {
        transition: all 0.3s ease-in-out;
        background: #ffffff !important;

        min-height: 53px !important;
        height: 53px !important;
        padding: 6px 12px;

        &:hover {
          border: 1px solid transparent !important;
        }
      }

      &.notHoverEffect {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  &__disabled * {
    cursor: default !important;

    .endIcon {
      pointer-events: auto;
    }
  }

  .dropdownRadio {
    width: 450px;
    right: 0;
    top: 58px;

    .list {
      margin: 0;
      overflow: visible;
      max-height: fit-content;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .dropdownRadioSmall {
    top: 30px;
  }
}