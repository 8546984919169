@import 'styles/helpers/variables.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 405px;
  padding: 16px;
  border-radius: 20px;
  background: var(--accent-light);
  color: var(--text-color);

  &__content {
    display: flex;
    flex-direction: column;
    @include font-12;

    &_title {
      color: var(--text-light);
      margin-bottom: 6px;
    }

    &_due {
      @include font-22-bold;
    }
  }

  &__actions {
    button {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 6px 24px 6px 20px;
      @include font-12-bold;

      svg {
        width: 16px;
        height: 16px;
        path {
          fill: $white;
        }
      }
    }

    &_zero {
      button:disabled {
        background-color: $white;
        color: var(--holder-color);

        svg {
          path {
            fill: var(--holder-color);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: unset;
    height: 192px;
  }
}
