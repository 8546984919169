@import '/src/styles/helpers/variables.scss';

.modal {
  position: fixed;
  top: 0;
  right: -1000px;
  z-index: 100;
  display: none;
  width: 100vw;
  height: 103vh;
  padding: 12px;
  background-color: $white;
  overflow-y: auto;
  transition: 0.6s;

  @media screen and (min-width: 1025px) {
    left: 0;
    right: unset;
    display: none;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
  }

  &.price-modal {
    left: 0;
    width: 101vw;
    right: unset;
    align-items: center;
    backdrop-filter: blur(1.5px);
    background: rgba(13, 9, 24, 0.56);
  }
}

.bottom-modal {
  position: fixed;
  left: 0;
  bottom: -1000px;
  width: 100vw;
  height: 100vh;
  padding: 12px;
  background-color: $white;
  overflow-y: auto;
  z-index: 100;
  transition: 0.6s;
  visibility: hidden;
}

.bottom-open {
  visibility: visible;
  bottom: 0px;

  .header {
    bottom: 12px;
    transition: 0.6s;
  }
}

.open {
  right: 0px;
  display: block;

  .header {
    right: 12px;
    transition: 0.6s;
  }

  @media screen and (min-width: 1025px) {
    right: unset;
    display: flex;
  }

  &.price-modal {
    right: unset;
    display: flex;

    .header {
      display: none;
    }

    .content {
      background-color: $white;
      border-radius: 20px;
      max-width: max-content;
      margin: auto;
      width: 100%;
      position: relative;
      padding: 0;
      height: unset;
    }

    .desktop {
      display: flex;
    }
  }
}

.header {
  position: fixed;
  z-index: 100;
  top: 12px;
  right: -1000px;
  width: calc(100% - 24px);
  height: 56px;
  background-color: $white;
  box-shadow:
    0px 15px 125px rgba(201, 180, 250, 0.13),
    0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
    0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
    0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
  border-radius: 28px;
  padding: 16px;

  @media screen and (min-width: 1025px) {
    display: none;
  }

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      @include font-14-bold;

      svg {
        width: 24px;
        height: 24px;
        display: block;
      }
    }

    .close {
      width: 21px;
      height: 21px;

      svg {
        fill: var(--text-color);
      }
    }
  }
}

.basic {
  box-shadow: none;
  border-radius: 0;
}

.content {
  padding-top: 81px;
  padding-bottom: 24px;
  z-index: 99;
  height: 100%;

  @media screen and (min-width: 1025px) {
    background-color: $white;
    border-radius: 36px;
    max-width: max-content;
    margin: auto;
    width: 100%;
    position: relative;
    padding: 0;
    height: unset;
  }
}

.sub-modal {
  z-index: 110;
  height: 100vh;
  padding-bottom: 60px;

  .header {
    right: 12px;
    transition: 0.6s;
  }
}

.desktop {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $white;
  position: absolute;
  right: -65px;
  top: 0px;
  cursor: pointer;

  @media screen and (min-width: 1025px) {
    display: flex;
  }
}

.mobile-filter {
  height: 100%;
  overflow: hidden;

  .content {
    overflow: auto;
  }
}
