@import 'styles/main.scss';

.formhint {
  width: 100%;
  color: var(--text-color);
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-top: 4px;
}
.formhint.left {
  justify-content: flex-start;
}
.formhint.right {
  justify-content: flex-end;
}

.input {
  width: 100%;
  border-radius: 12px;

  background: var(--accent-light);
  color: var(--text-color);
  transition: 0.3s;

  padding: 8px 50px 6px 12px;
  @include font-14;
  background: var(--accent-light);
  border: none;

  &:hover {
    background: white;
  }

  &::placeholder,
  input::placeholder {
    color: var(--text-light);
  }

  @media screen and (max-width: $xs) {
    @include font-16;
  }
}

.input:focus + .inputLabelPlaceholderDefault {
  @include font-12;
  top: 25%;
  transform: translateY(-25%);
}

.textarea {
  background: var(--accent-light);
  border: 1px solid var(--accent-second);
  border-radius: 8px;
  color: var(--text-color);
  font-size: 14px;
  padding: 8px 12px;
  transition: 0.3s;
  width: 100%;
  min-height: 72px;

  &::placeholder {
    color: var(--text-light);
  }
}

.inputicon,
.formtextarea.icon {
  padding-left: 52px;
}

.inputCurrency,
.formtextarea.icon {
  padding-left: 30px;
}

.inputWrap,
.formtextareaWrap {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputInner,
.formtextareaInner {
  position: relative;
}

.input:disabled,
.formtextarea:disabled {
  cursor: default;
  opacity: 0.5;

  &:hover {
    background: var(--accent-light);
  }
}

.input:not(:disabled):hover,
.form__textarea:not(:disabled):hover {
  border: 1px solid var(--accent-second);
  background-color: $white;
}

.input:not(:disabled):focus,
.form__textarea:not(:disabled):focus {
  border: 1px solid var(--accent-color);
  background-color: $white;
}

.input::placeholder,
.form__textarea::placeholder {
  color: var(--text-light);
}

.inputError,
.textareaError {
  border-color: var(--error-color) !important;
  background-color: var(--error-8);
  padding-right: 43px;
}

.textarea {
  resize: none;
}

.inputLabelPlaceholderDefault {
  pointer-events: none;
}

.iconWrap {
  position: absolute;
  left: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}

.currencyWrap {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  color: var(--text-light);
}

.inputLabel {
  height: 56px;
  border: 1px solid transparent;
  transition: border, background-color, 0.3s;
  padding-top: 20px;

  .input:hover,
  .form__textarea:hover,
  .input:focus,
  .form__textarea:focus {
    border: 1px solid var(--accent);
    background-color: $white;
  }
}

.inputLabelEmptyInput {
  padding-top: 8px;
}

.inputLabelPlaceholder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: var(--text-light);
  transition:
    top 0.3s,
    transform 0.3s;
  @include font-14;

  &.disabled {
    opacity: 0.5;
  }
}

.inputLabelPlaceholderActive {
  @include font-12;
  top: 25%;
  transform: translateY(-25%);
}

.inputIconPlaceholder {
  padding-left: 52px;
}

.clearIcon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  cursor: pointer;
}

.error {
  position: absolute;
  color: var(--text);
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: 18px;
}

.ru {
  background: $white;
}

.bigInput {
  left: 12px;
}

.inputLabelPlaceholderDefaultFocused {
  @include font-12;
  top: 25%;
  transform: translateY(-25%);
}

.errorText {
  margin-top: 4px;
  color: var(--error-main);
  @include font-12;
}
