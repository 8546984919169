@import 'styles/helpers/variables.scss';

.price-block {
  padding: 32px 24px;
  box-shadow: var(--preview-box-shadow);
  border-radius: inherit;
  background-color: $white;

  .divider {
    display: block;
    margin: 24px 0;
    border-top: 1px solid var(--divider-color);
    border-radius: 2px;
    opacity: 30%;
  }

  @media screen and (min-width: 961px) {
    width: 354px;
  }

  .price {
    margin-bottom: 20px;

    .text {
      @include font-14;
    }

    .cost {
      @include font-32-bold;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;

      &--small {
        @include font-22-bold;

        @media screen and (max-width: 1024px) {
          @include font-20-bold;
        }
      }
    }

    .discount {
      @include font-20-bold;
      color: var(--text-light);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 8px;
        width: 100%;
        border-bottom: 2px solid var(--text-light);
        transform-origin: left bottom;
        transform: rotate(-8deg);
      }

      &--small {
        @include font-14-bold;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    pointer-events: none;

    .addToCartBtn {
      width: 100%;
      cursor: unset;
    }

    .button {
      height: 48px;
      width: 100%;
      cursor: unset;

      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }

    .favorite {
      height: 100%;
      width: 48px;
      cursor: unset;

      svg {
        fill: var(--accent-color);
      }

      @media screen and (max-width: 1024px) {
        height: 40px;
        width: 40px;
      }
    }
  }

  .priceDesciption {
    @include font-12;
    color: var(--text-grey);
    margin-top: 24px;
  }
}

.short {
  background-color: transparent;
  box-shadow: none;
  padding: 0 20px 0 0;
  z-index: 10;

  .price {
    display: none;
  }

  .priceDesciption {
    display: none;
  }

  @media screen and (max-width: $xs-up) {
    padding-bottom: 12px;
  }
}

.modal {
  position: relative;
  display: flex;
  min-height: 356px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .left {
    padding: 40px;
    width: 472px;
    color: var(--text);

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }

    .cart-title {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    .text {
      margin-bottom: 32px;
      @include font-14;
    }

    .label {
      margin-bottom: 8px;
      margin-top: 32px;
      @include font-12-bold;
    }

    .add {
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .sources {
      width: 100%;
      // overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 1024px) {
        max-height: none;
      }

      .source {
        input {
          height: 40px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .source-wrapper {
        display: flex;
        width: 100%;
        gap: 8px;

        .source {
          width: 344px;

          @media screen and (max-width: 1024px) {
            max-width: 344px;
            width: auto;
          }
        }

        .delete {
          width: 40px;
          height: 40px;
          border: 1px solid #e4e4e5;
          border-radius: 8px;
          padding: 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    height: 40px;
    gap: 8px;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      height: auto;
      margin-top: 24px;
    }

    .button {
      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }
  }

  .right {
    position: relative;
    width: 288px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text);
    border-radius: 0 36px 36px 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .bg-image {
      object-fit: fill;
      /* height: 410px; */
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .price-count {
      position: relative;
      box-shadow:
        0px 15px 125px rgba(201, 180, 250, 0.13),
        0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
        0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
        0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
      border-radius: 16px;
      background-color: $white;
      padding: 17px 16px;
      width: 224px;

      @media screen and (max-width: 1024px) {
        position: relative;
        background-color: var(--accent-light);
        box-shadow: none;
        width: 100%;
        left: auto;
      }

      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        @include font-12;

        span {
          @include font-14-bold;
        }
      }

      .total {
        margin-top: 13px;
        padding-top: 12px;
        border-top: 1px solid rgba(var(--text-light), 0.3);
        @include font-14;

        span {
          @include font-22-bold;
        }
      }
    }

    .hint {
      color: var(--text-light);
      margin-top: 12px;
      @include font-12;
    }
  }
}

.priceDeals {
  padding-top: 64px;

  @media screen and (max-width: $xs-up) {
    padding-top: 0;
  }
}

.tooltipWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -15px;

  .tooltip {
    min-width: 250px;
    white-space: normal;
    color: var(--text);
    display: block;
    background-color: #fff;
    font-size: 14px;
    line-height: 140%;
    box-shadow:
      0px 0px 8px rgb(0 0 0 / 10%),
      0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    text-align: center;

    * {
      white-space: normal;
    }

    :global(.common__tooltip-inner) {
      box-shadow: none;
      height: unset;
      background: transparent;
      display: block;
    }

    :global(.common__tooltip-text) {
      width: 100%;
      display: block;
    }

    @media screen and (max-width: $xs-up) {
      min-width: 200px;
    }
  }
}

.tooltipContainer {
  position: relative;
  top: -2px;
  margin-left: 6px;
}

.closeTimeTooltip {
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
}

.closeTimeInner {
  display: flex;
  color: var(--text-light);
  @include font-12;
  padding-right: 5px;
}

.closeTime {
  font-weight: 500;
  padding-left: 3px;
}

.tagInner {
  position: relative;
  top: 2px;
}

.in-cart {
  border: 1px solid var(--accent-color);
  background-color: inherit;
  color: var(--accent-color);
  display: flex;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px 4px 0;
  margin: -3px 0 0 8px;
  border-radius: 50%;
  @include font-12-bold;
  background: var(--accent-color);
  color: $white;
}

.counterMedium {
  width: 20px;
  height: 20px;
}

.disclaimer {
  color: var(--accent-color);
  cursor: unset;
}
