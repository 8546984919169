@import 'styles/helpers/variables.scss';

.goals {
  &__title {
    @include font-14-bold;
    color: var(--holder-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .end-icon__tooltip {
      margin-left: unset !important;
      font-weight: 400;
    }

    .infoIcon {
      display: flex;
      svg {
        margin-left: 2px;
        width: 16px;
        height: 16px;
      }
    }

    @media screen and (max-width: 768px) {
      @include font-12;
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    min-height: 128px;
    padding: 12px;
    gap: 8px;
    margin-bottom: 24px;
    border-radius: 20px;
    background: var(--dotted-border);
    box-shadow: var(--goals-block-shadow);

    &_active  {
      align-content: flex-start;
    }

    &.error {
      position: relative;
      background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23EA4A70' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      .goals__empty_text {
        color: $red-middle;
      }

      .goals__empty_btn {
        color: $red-middle;
        background: $red-light;
        svg {
          stroke: $red-middle;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 12px;
    gap: 4px;
    border-radius: 36px;
    border: 1px solid var(--accent-color);
    @include font-12;
    color: var(--text-color);
    width: fit-content;
    max-width: 100%;

    .divElement {
      width: fit-content;
      min-width: 10px;
      @include font-12;
      line-height: 1;
      padding-left: 0;
      padding-right: 0;
      border: none;
      outline: none;
      resize: none;
      word-break: break-all;
    }

    @media screen and (max-width: $sm) {
      border-radius: 16px;

      .divElement {
        font-size: 16px !important;
      }
    }

    .input {
      width: 10px;
      min-width: 10px;
      @include font-12;
      line-height: 1;
      padding-left: 0;
      padding-right: 0;
      border: none;
      outline: none;
      resize: none;
    }

    .goalLength {
      color: #c4bbd2;
      margin-left: 4px;
    }

    &__tag {
      max-width: 100%;
      overflow: unset;
    }

    div[contenteditable='true'] {
      @include font-12;
      min-width: 1ch;
    }
    svg {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      stroke: var(--accent-color);
    }

    &_active {
      svg {
        transform: rotate(45deg);
      }
    }

    &_add {
      background: var(--accent-light);
      border: 1px solid transparent;
      color: var(--accent-color);
      cursor: pointer;

      div[contenteditable='true'] {
        display: none;
      }
      svg {
        display: none;
      }
    }
  }

  &__bottom_text {
    @include font-11-bold;
    color: var(--holder-color);
    margin-bottom: 12px;
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  &__empty_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }
  &__empty_text {
    @include font-12-bold;
    color: var(--holder-color);
    margin: 0;
    text-align: center;
  }
  &__empty_btn {
    display: flex;
    align-items: center;
    background: var(--accent-light);
    color: var(--accent-color);
    padding: 6px 20px 6px 24px;
    border-radius: 50px;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;

    svg {
      stroke: var(--accent-color);
    }
  }
}

@media (max-width: $sm) {
  .goals {
    &__main {
      margin-bottom: 20px;
    }

    &__item, input, textarea {
      font-size: 16px !important;
    }

  }
}
