@import 'styles/main.scss';

.wrapper {
  padding: 36px 0;

  @media (max-width: 1024px) {
    padding: 48px 0 110px 0;
  }
}

.projects-wrapper {
  margin-top: 40px;
  border-radius: 36px;
  background: $white;
  box-shadow: var(--top-shadow);
  padding: 24px 24px 40px 24px;
  @include font-12;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding: 24px 20px;
  }

  @media screen and (max-width: 680px) {
    margin: 24px -20px 0px -20px;
  }
}

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__tabs {
    margin-top: 24px;
  }
}

.tabs {
  margin-top: 0 !important;
}

.title {
  @include font-32-bold;
  color: var(--text-color);

  @media (max-width: $sm) {
    @include font-22-bold;
  }

  span {
    position: absolute;
    color: var(--text-light);
    @include font-12-bold;
  }
}

.mobile-sort {
  display: flex;

  svg {
    color: var(--holder-color);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  border-radius: 36px;
  padding: 92px 32px;
  &__header {
    @include font-32-bold;
    margin-bottom: 16px;
  }

  &__text {
    @include font-14;
    margin-bottom: 40px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 9px 32px 9px 28px;
    border-radius: 80px;
    background: var(--accent-color);
    color: $white;
    @include font-14-bold;

    &_disabled {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }

    svg {
      width: 20px;
      height: 20px;
      stroke: $white;
    }
  }

  @media (max-width: 680px) {
    padding: 32px 56px;
    margin: 40px -20px 0px -20px;
    &__header {
      @include font-22-bold;
      margin-bottom: 8px;
    }
    &__text {
      margin-bottom: 32px;
    }
  }
}

.hintsTooltip {
  width: 350px;
  max-width: unset;

  &__top--center {
    left: 50% !important;
  }
}

.skeletonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  & > div {
    width: 100px;
    height: 100%;
  }
}

.skeletonRow {
  height: 40px;

  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}