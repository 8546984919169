@import 'styles/helpers/variables.scss';

.footer {
  margin: 40px 0;
  color: var(--text-color);

  @media screen and (max-width: $xs-up) {
    padding-bottom: 40px;
  }

  .title {
    @include font-16-bold;
  }

  .description {
    @include font-14;
    margin-bottom: 24px;
  }

  .companyList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 600px;
    opacity: 0.5;

    @media screen and (max-width: $xs-up) {
      max-width: 100%;
    }

    .companyItemWrapper {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (max-width: $xs) {
        flex-direction: column;
      }

      .companyItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--accent-light);
        padding: 8px 12px;
        border-radius: 12px;
        width: 100%;
        height: 56px;

        .copyIcon {
          cursor: pointer;
          width: 40px;
          height: 40px;

          svg {
            width: 100%;
            height: 100%;
          }

          path {
            transition: stroke 0.2s ease-in-out;
            stroke: var(--gray-color);
          }

          &:hover {
            path {
              stroke: var(--accent-color);
            }
          }
        }

        .companyNameLabel {
          margin: 0;
          color: var(--text-light);
          @include font-12;
        }

        .companyName {
          margin: 0;
          @include font-14;
        }
      }
    }
  }
}

.copyWrapper {
  position: relative;
  padding-top: 20px;
  margin-right: -10px;

  .tooltip {
    position: absolute;
    z-index: 10;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    border: 1px solid var(--accent-color);
    border-radius: 12px;
    background-color: $white;
    animation: fadeOut 1s ease-in forwards;
    animation-delay: 2s;

    @media screen and (max-width: $xs-up) {
      top: -35px;
      left: auto;
      right: -10px;
      transform: translateX(0%);
      border-radius: 8px;
      @include font-12;
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
