@import 'styles/main.scss';

.top-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.email {
  grid-column-start: 1;
  grid-column-end: 3;
}

.goals {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    @include font-14-bold;
  }
}

.check {
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }
}

.bottom-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  column-gap: 16px;
  margin-bottom: 32px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    column-gap: 16px;
  }
}

.submit {
  display: flex;
}

.submit-button {
  height: 48px;

  &:disabled {
    background: var(--accent-third);
    color: var(--text-light);
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    width: 100%;
  }
}

.text {
  @include font-14;
  display: flex;
  max-width: 360px;
  flex-wrap: wrap;

  span {
    margin-right: 4px;
  }

  .link {
    margin-right: 4px;

    a {
      color: var(--accent-color);
    }
  }
}

.tabs {
  width: 100%;
  margin-bottom: 16px;
}

.comment {
  margin-bottom: 28px;
}

.input-field {
  position: relative;
  font-weight: 500;

  &--full {
    grid-column: span 2;
  }

  .item-label {
    @include font-12-bold;
    margin-bottom: 4px;
    color: var(--text-color);
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: 12px;
  z-index: 10;

  :global(.common__tooltip.top) {
    top: -15px;
  }

  :global(.common__tooltip-inner) {
    padding: 6px 12px;
    font-weight: 400;
    box-shadow: none;
    border: 1px solid #f3ecff;
    max-width: max-content;

    &::after {
      content: none;
    }
  }

  @media screen and (max-width: 1024px) {
    bottom: 5px;

    :global(.common__tooltip.top) {
      left: -60px;
    }
  }
}

.captcha {
  position: relative;
  margin-top: 32px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}

.register_theme {
  input {
    height: 38px;
    border: 1px solid var(--accent-second);
    &::placeholder {
      color: var(--holder-color);
    }
    &:hover {
      border: 1px solid var(--accent-color);
    }
  }
}
