@import '/src/styles/helpers/variables.scss';

.switch {
  display: flex;
  align-items: center;
}

.label {
  @include font-16;
  margin-right: 12px;
  color: var(--text-color);

  &__withCounter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .counter {
      color: #9c93aa;
      @include font-16;
    }
  }
}

.switcherContainer {
  position: relative;
  width: 42px;
  height: 24px;
  background: var(--gray-second);
  border-radius: 24px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: var(--gray-color);
  }

  &.on {
    background: var(--switcher-active-bg);
  }
}

.knob {
  position: absolute;
  top: 2px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  transition: left 0.3s;

  &.on {
    left: 20px;
  }
}
