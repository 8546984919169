@import 'styles/helpers/variables.scss';

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  color: var(--text-color);

  @media screen and (max-width: $xs-up) {
    flex-direction: column;
  }

  .titleWrap {
    margin-bottom: 8px;

    .title {
      @include font-22-bold;
      margin: 0;
    }
  }

  .helperInfo {
    max-width: 600px;
  }

  .info {
    margin: 0 0 24px;
    @include font-14();

    @media screen and (max-width: $xs-up) {
      margin-bottom: 24px;
    }

    &__link {
      color: var(--accent-color);
      transition: all ease-in-out 0.3;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .approved {
    background-color: var(--ui-02-8);
    padding: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);

    @media screen and (max-width: $xs-up) {
      width: 100%;
      margin: 0 0 24px;
    }

    .approvedText {
      @include font-14;
    }
  }
}

.attention {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--ui-01-8);
  margin-bottom: 24px;
  max-width: 600px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    @include font-14-bold;
  }

  &__description {
    @include font-12;
  }

  &__link {
    cursor: pointer;
    color: var(--accent-color);
  }
}
