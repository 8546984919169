@import 'styles/main.scss';

// .form {
// }

.top-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  row-gap: 24px;
  column-gap: 16px;
  margin-bottom: 24px;

  .register_theme {
    input {
      height: 38px;
      border: 1px solid var(--accent-second);
      &::placeholder {
        color: var(--text-light);
      }
      &:hover {
        border: 1px solid var(--accent-color);
      }
    }
  }

  .item-label {
    @include font-12-bold;
    margin-bottom: 4px;
    color: var(--text-color);
  }

  .fullName {
    grid-area: 1 / 1 / 1 / 3;
    position: relative;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.email {
  grid-column-start: 1;
  //   grid-column-end: 3;
  position: relative;
}

.phone {
  position: relative;
}

.bottom-fields {
  position: relative;
  //   display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 64px;
  column-gap: 16px;
  margin-bottom: 32px;

  .item-label {
    @include font-12-bold;
    margin-bottom: 4px;
    color: var(--text-color);
  }

  .register_theme {
    input {
      height: 38px;
      border: 1px solid var(--accent-second);
      &::placeholder {
        color: var(--text-light);
      }
      &:hover {
        border: 1px solid var(--accent-color);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    column-gap: 16px;
  }
}

.submit {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
}

.button {
  height: 48px;

  &:disabled {
    background: var(--accent-third);
    color: var(--text-light);
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    width: 100%;
  }
}

.checkbox-block {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.text {
  @include font-12;
  display: flex;
  max-width: 360px;
  flex-wrap: wrap;

  span {
    margin-right: 4px;
    color: var(--text-color);
  }

  .link {
    margin: 0 4px;

    &_privacy {
      margin: 0;
    }

    a {
      color: var(--accent-color);
    }
  }
}

.input-field {
  position: relative;
}

.show-password {
  position: absolute;
  right: 13px;
  bottom: 10px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.password-check {
  position: absolute;
  right: 0;
  top: -140px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  .title {
    @include font-14-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-14;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}

.slight-up {
  top: -140px;
}

.repeat-check {
  position: absolute;
  right: 0;
  top: -69px;
  z-index: 11;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.06);
  width: 213px;
  height: fit-content;

  .title {
    @include font-14-bold;
    margin-bottom: 8px;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .check {
    display: flex;
    gap: 11px;
  }

  .icon {
    margin-top: 2px;
  }

  .name {
    color: var(--text-light);
    @include font-14;
  }

  .correct {
    color: $green;
  }

  .wrong {
    color: $red;
  }
}

.error {
  position: absolute;
  color: $red;
  width: fit-content;
  right: 12px;
  white-space: nowrap;
  bottom: 12px;
  z-index: 10;

  :global(.common__tooltip.top) {
    min-width: unset;
    top: -15px;
  }

  :global(.common__tooltip-inner) {
    padding: 6px 12px;
    box-shadow: none;
    border: 1px solid #f3ecff;
    max-width: max-content;

    &::after {
      content: none;
    }
  }

  @media screen and (max-width: 1024px) {
    bottom: 5px;

    :global(.common__tooltip.top) {
      left: -60px;
    }
  }
}

.captcha {
  position: relative;
  margin-top: 32px;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    margin-top: 28px;
    margin-bottom: 22px;
  }
}