@import 'styles/helpers/variables.scss';

.noticeBlock {
  margin: 12px 0;
  max-width: 748px;

  @media screen and (max-width: $sm) {
    margin: 0 20px;
  }
}

.wrapper {
  padding: 40px;
  position: relative;
  box-shadow: var(--preview-box-shadow);
  border-radius: 32px;

  @media screen and (max-width: $sm) {
    margin: 8px 0 0 0;
    padding: 20px;
  }
}

.back {
  margin-bottom: 32px;
  @include font-12-bold;

  svg {
    fill: var(--accent-color);
  }

  a {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    color: var(--accent-color);
    padding: 8px 24px 8px 0;
    line-height: 1.5;
  }
}

.left {
  padding-right: 64px;
  grid-column: span 8;

  @media screen and (max-width: $xs-up) {
    padding-right: 0;
    grid-column: span 12;
  }
}

.grid {
  z-index: 1;
  position: relative;
}

.deals {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 36px 36px 0 0;
  box-shadow:
    0px 33px 100px rgba(28, 20, 37, 0.0201946),
    0px 7.37098px 22.3363px rgba(28, 20, 37, 0.0298054),
    0px 2.19453px 6.6501px rgba(28, 20, 37, 0.05);
  background: var(--gradient-3);
  background: var(--hotdeal-item-back);
  background-size: cover;

  @media screen and (min-width: 960px) {
    width: 354px;
  }
}

.price-block {
  border-radius: 36px;
}

.deals + .price-block {
  border-radius: 0 0 36px 36px;
}

.deal {
  position: relative;
  top: 3px;
  border-radius: 32px 32px 0 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-shadow:
    0px 33px 100px rgba(65, 25, 111, 0.0201946),
    0px 7.37098px 22.3363px rgba(65, 25, 111, 0.0298054),
    2px 1px 2px rgba(32, 13, 55, 0.06);
  color: $white;

  &.spanish {
    font-size: 17px;
    line-height: 28px;
  }
}

.priceDeals {
  padding-top: 64px;
}

.sticky {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
    display: none;
  }
}

.manager {
  display: flex;
}

.recommended {
  display: flex;
}

.goals {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;

  &_title {
    @include font-22-bold;
    margin-bottom: 24px;
  }

  &_container {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  &_tag {
    background: var(--accent-light);
    border-radius: 36px;
    padding: 4px 12px;
    width: fit-content;
    color: var(--accent-color);
    @include font-14;
  }
}
