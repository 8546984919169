@import 'styles/main.scss';

.card {
  width: 100%;
  border-radius: 36px;
  background: #fff;
  padding: 32px;
  box-shadow: var(--top-shadow);

  @media (max-width: 1024px) {
    padding: 24px 20px;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-direction: row;

    &__left {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 8px;
      flex-direction: column;
    }

    &__right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;
      position: relative;

      .mainButton {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 38px;
        padding: 8px 24px 8px 20px;
        @include font-16-bold;

        &__icon {
          svg {
            display: flex;
            align-items: center;
          }
        }
      }

      .moreButton {
        padding: 10px;
        border-radius: 80px;

        &__dotIcon {
          display: flex;
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      .dropdown {
        position: absolute;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        padding: 4px;
        box-shadow: var(--cart-shadow);
        width: 100px;
        border-radius: 8px;
        top: 45px;
        right: 0;
        z-index: 1;

        &__item {
          cursor: pointer;
          padding: 4px 8px;
          @include font-14;
          color: var(--text-color);
        }
      }
    }

    &__bottom {
      display: flex;
      align-items: center;

      &__separate {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--accent-second);
        margin: 0 6px;
      }
    }

    @media (max-width: 1024px) {
      &_mobile {
        display: block;
      }
    }
  }

  &__source {
    gap: 2px;
    color: var(--accent-color);
    @include font-16;

    &__mobile {
      @include font-12-bold;
      color: var(--accent-color);
      gap: 2px;
    }
  }

  &__header {
    margin: 0;
    @include font-22-bold;

    @media (max-width: 1024px) {
      margin: 12px 0 4px 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 16px;
    color: var(--text-color);
    margin-bottom: 8px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  &__link:hover {
    color: var(--accent-color);
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  &__tag {
    border-radius: 36px;
    padding: 4px 12px;
    @include font-11-bold;
    letter-spacing: 0.24px;
    color: var(--accent-color);
    display: inline-block;

    &_purple {
      color: var(--ui-color-3);
      background: rgba(var(--ui-3-rgb), 0.08);
    }

    &_yellow {
      color: #fab005;
      background: rgba(250, 176, 5, 0.08);
    }

    &_green {
      color: #37b24d;
      background: rgba(55, 178, 77, 0.08);
    }

    &_red {
      color: var(--error-color);
      background: rgba(240, 62, 62, 0.08);
    }
  }

  &__id {
    @include font-12;
    &:last-child {
      font-weight: 500;
    }
    color: var(--holder-color);
    margin-right: 3px;
  }

  &__grid {
    display: flex;
    gap: 20px;

    @media (max-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
    }

    &__card {
      padding: 16px;
      background: var(--accent-light);
      border-radius: 20px;
      flex: 1 1;
      @include font-12;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 159px;

      @media (max-width: 1024px) {
        padding: 16px;
        min-width: calc(50% - 20px);
        gap: 24px;
        height: auto;
        min-height: 132px;
      }

      &_manager {
        @media (max-width: 1024px) {
          .card__grid__card__header {
            margin-bottom: 0;
          }

          flex-direction: row;
          align-items: center;
        }
      }

      &__header {
        margin-bottom: 16px;
        color: var(--text-color);
        font-size: 14px;
        line-height: 140%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .priceTag {
          position: relative;
          color: var(--accent-color);
          padding: 4px 8px;
          background-color: #ffffff;
          border-radius: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          cursor: pointer;

          .priceTooltip {
            top: 50%;
            justify-content: center;
            left: 0;
          }
        }

        :global(.common__tooltip-inner) {
          width: auto;
          background: #615a6d;
          color: #ffffff;
          padding: 6px 12px;
          &:after {
            bottom: 0;
            top: 0;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
            left: calc(100% - 1px);
            border-top: 10px solid #615a6d;
          }

          :global(.common__tooltip-text) {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .tooltipItem {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-bottom: 4px;
              font-size: 11px;
              line-height: 140%;
              align-items: flex-start;

              .price {
                @include font-14-bold;
              }
            }
          }
        }

        @media (max-width: 1024px) {
          margin-bottom: 12px;
          font-size: 12px;
        }
      }

      &__coast {
        font-size: 36px;
        font-weight: 500;
        line-height: 110%;
        margin-bottom: 4px;

        @media (max-width: 1024px) {
          margin-top: 4px;
        }
      }

      &__text {
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 43px;
      }

      &__budget {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 6px;

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 8px;
        }

        &__line {
          width: 100%;

          svg {
            width: 100%;
            height: 4px;
          }
        }

        &__text {
          flex-shrink: 0;
        }
      }

      &__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 8px;

        @media (max-width: 1024px) {
          width: 48px;
          height: 48px;
          margin-bottom: 0;
          margin-right: 8px;
        }
      }

      &__title {
        font-size: 18px;
        line-height: 140%;
        margin: 0;
      }

      &__btn {
        @include font-14-bold;
        padding: 12px 20px 12px 24px;
        border-radius: 80px;
        background: #fff;
        align-self: flex-start;
        text-align: center;
        color: var(--accent-color);
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        margin-top: auto;

        svg {
          display: none;
        }

        @media (max-width: 1024px) {
          margin-top: 0;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          padding: 0;

          span {
            display: none;
          }

          svg {
            display: block;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .dataCount {
      @include font-32-bold;
      white-space: nowrap;
      color: var(--text-color);

      @media (max-width: 1024px) {
        @include font-24-bold;
      }
    }

    .percentage {
      @include font-14;
      line-height: 1.6;
      color: #37a52e;

      &.price {
        @include font-14-bold;
      }

      @media (max-width: 1024px) {
        font-size: 14px;
      }

      &.price {
        color: var(--text-color);

        &__long {
          @include font-14-bold;
          line-height: 1.6;
        }
      }

      svg {
        color: #37a52e;
        fill: #37a52e;
        path {
          stroke: #37a52e;
        }
      }

      &.down {
        color: #da2626;

        .arrowIcon {
          transform: rotate(180deg) scale(-1, 1);
          fill: #da2626;
          path {
            stroke: #da2626;
          }
        }
      }

      &.none {
        color: #9c8b8b;
      }

      .arrowIcon {
        margin-right: 3px;
        width: 9px;
        height: 9px;
        margin-left: 5px;
        stroke-width: 1.5px;
      }
    }

    .bottomDescription {
      color: var(--holder-color);

      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }

    &__inner {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;

      @media (max-width: $sm-up) {
        gap: 4px;
        &.priceBox {
          flex-direction: column;
          gap: 0;
          align-items: flex-start;
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--accent-color);
      @include font-14-bold;
    }

    .step {
      width: 100%;
      position: relative;
      margin-bottom: 16px;

      & > svg {
        flex-shrink: 0;
      }

      &__date {
        @include font-14;
        width: 85px;
        margin-left: auto;
      }

      &__block {
        margin-top: 1px;
        flex-shrink: 0;

        span {
          color: var(--text-color);
          font-weight: 500;
          @include font-16-bold;
        }

        p {
          margin: 0;
          margin-bottom: 6px;
          @include font-14;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .empty {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(var(--text-light), 0.2);
      }

      &_time {
        &::after {
          content: '';
          opacity: 0.2;
        }
      }

      &_empty {
        .step__block span {
          font-weight: 400;
        }

        &::after {
          content: '';
          opacity: 0.2;
        }
      }

      &::after {
        content: '';
        width: 2px;
        height: calc(100% + 2px);
        position: absolute;
        top: 18px;
        left: 0.65em;
        background-color: var(--accent-color);
      }

      &:last-child {
        margin-bottom: 0px;

        &::after {
          content: none;
        }
      }

      .name {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        @include font-16-bold;

        span {
          padding-top: 3px;
        }
      }

      .past {
        color: var(--text-light);
      }
    }
  }
}
