@import 'styles/helpers/variables.scss';

.card {
  padding: 16px;
  background: var(--accent-light);
  border-radius: 20px;
  flex: 1 1;
  @include font-12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 159px;

  .modal {
    button {
      padding: 0 !important;
      cursor: alias !important;
      pointer-events: none !important;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 16px;
    min-width: calc(50% - 20px);
    gap: 24px;
    height: auto;
  }

  &__header {
    color: var(--text-color);
    @include font-14;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      max-width: 200px;
    }

    .tooltipContainer {
      position: relative;
      z-index: 100;
      height: 21px;
    }

    :global(.common__tooltip-inner) {
      min-width: 200px;
      top: -12px;
      background: #615a6d;
      color: $white;
      padding: 6px 12px;
      &:after {
        bottom: 0;
        top: 0;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        left: calc(100% - 1px);
        border-top: 10px solid #615a6d;
      }
    }

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }

    @media screen and (max-width: 680px) {
      span {
        max-width: 100px;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    &_inner {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
    }

    &_count {
      display: inline-block;
      text-align: end;
      font-size: 32px;
      font-weight: 500;
      color: var(--text-color);

      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    &_percentage {
      @include font-14;
      color: #4cbb43;
      transform: translateY(3px);

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }

      .arrowIcon {
        margin-right: 3px;
        width: 9px;
        height: 9px;
        margin-left: 5px;
        stroke-width: 1.5px;
      }

      svg {
        color: #37a52e;
        fill: #37a52e;
        path {
          stroke: #37a52e;
        }
      }

      &.down {
        color: #da2626;

        .arrowIcon {
          transform: rotate(180deg) scale(-1, 1);
          fill: #da2626;
          path {
            stroke: #da2626;
          }
        }
      }

      &.none {
        color: #9c8b8b;
      }

      &.zero {
        color: var(--holder-color);
      }
    }
  }

  .filterText {
    color: var(--holder-color);

    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }

    @media screen and (max-width: 680px) {
      font-size: 11px;
    }
  }
}
