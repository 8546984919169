.loaderWrapper {
  margin: 0 auto;
  position: relative;
  height: 100%;
  width: 100%;
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
