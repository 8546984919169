@import 'styles/helpers/variables.scss';

.preview-item {
  display: flex;
  align-items: center;
  gap: 4px;
  max-height: 24px;
  max-width: 43%;
  padding: 2px 12px;
  border-radius: 36px;
  background: var(--accent-color);
  color: $white;
  @include font-12;

  &--number {
    display: flex !important;
    border-radius: 48px;
    background: $white;
    min-width: 12px;
    width: 12px;
    height: 12px;
    color: var(--accent-color);
    align-items: center;
    justify-content: center;
    @include font-9-bold;
    line-height: 1.3;
  }

  span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    background: transparent;

    svg {
      path {
        fill: $white;
      }
      transform: scale(0.8);
    }
  }
}

.shrink {
  min-width: 70px;
}

.hidden {
  visibility: hidden;
}
