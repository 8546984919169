@import 'styles/helpers/variables.scss';

.react-datepicker-wrapper {
  width: 100%;
}

.datepicker-full {
  &.react-datepicker {
    border: none;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    padding: 0;
  }

  .react-datepicker__header {
    font-size: 14px;
    border-bottom: none;
    background: $white;
  }

  .react-datepicker__month-text {
    @include font-14;
    padding-top: 8px;
    width: 115px;
    height: 36px;
    margin: 3px;

    @media screen and (max-width: 400px) {
      width: 110px;
    }

    @media screen and (max-width: 387px) {
      width: 100px;
    }
  }
}

.react-datepicker {
  background: $white;
  border-radius: 12px;
  border: 1px solid var(--accent-second);
  width: 100%;

  &-wrapper {
    width: 100%;
  }

  &__triangle {
    fill: var(--accent-light) !important;
    color: var(--accent-light) !important;
    stroke: var(--accent-second);
  }

  &__header {
    width: 100%;
    @include font-12;
    border-radius: 12px 12px 0px 0px !important;
    border-bottom: 1px solid var(--accent-second);
    background: var(--accent-light);
  }

  &-year-header {
    font-weight: 700;
  }

  &__current-month {
    font-size: inherit;
    font-weight: 700;
    color: var(--text-color);
  }

  &__navigation {
    &-icon::before {
      width: 8px;
      height: 8px;
      border-color: var(--text-light);
      border-width: 1px 1px 0 0;
    }
  }

  &__month {
    margin: 0;
    padding: 6px 4px;

    .react-datepicker__day {
      background: var(--accent-light);
      border-radius: 4px;

      &--today {
        background: var(--text-light);
      }

      &:hover,
      &--in-range,
      &--in-selecting-range {
        background: var(--accent-second);
        color: var(--text-color);
      }

      &--selected,
      &--keyboard-selected,
      &--range-end,
      &--range-start,
      &--selecting-range-start {
        background: var(--accent-color);
        color: $white;
      }

      &--keyboard-selected:not(.react-datepicker__day--selecting-range-start) {
        &[aria-selected='false'] {
          background: var(--accent-second);
          color: var(--text-color);
        }
      }
    }
  }

  &__month-text {
    @include font-12;
    padding: 4px 12px;
    background: var(--accent-light) !important;
    border-radius: 4px !important;

    &:hover,
    &--in-range,
    &--in-selecting-range {
      background: var(--accent-second) !important;
      color: var(--text-color) !important;
    }

    &--selected,
    &--range-end,
    &--range-start,
    &--selecting-range-start {
      background: var(--accent-color) !important;
      color: $white !important;
    }

    &--today {
      background: var(--text-light) !important;
      font-weight: 700;
      color: var(--text-color) !important;
    }

    //выбранный месяц, но не в выбранном году (для одиночного выбора)
    &--selected,
    &--keyboard-selected {
      &[aria-selected='false'] {
        background: var(--accent-second) !important;
        color: var(--text-color) !important;
      }
    }
  }
}
