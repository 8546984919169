@import 'styles/helpers/variables.scss';

.tabs {
  display: inline-flex;
  padding: 4px;
  border-radius: 20px;
  background: var(--accent-light);
  max-width: max-content;
  @include font-14;

  &__tab {
    cursor: pointer;
    border-radius: 36px;
    padding: 6px 16px 5px 16px;
    display: flex;
    align-items: center;
    color: var(--text-color);

    &--active {
      box-shadow: 0px 3px 8px 0px rgba(201, 180, 250, 0.13);
      background: $white;
      color: var(--accent-color);
    }
  }
}
