@import '~styles/main.scss';

.container {
  max-width: 886px;
  color: var(--text-color);

  input {
    color: var(--text-color);
  }

  @media (max-width: $xs-up) {
    padding-bottom: 50px;
    margin-bottom: 70px;
  }
}

.title {
  @include font-22-bold;
  margin: 0;
}

.info {
  margin: 0;
  margin-top: 8px;
  margin-bottom: 24px;
  @include font-14;
}

.inputWrap {
  position: relative;

  .inputRight {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 16px;

    .selectRole {
      min-width: 150px;
      padding-top: 2px;
    }

    .button {
      height: fit-content;
    }
  }
}

.empty {
  padding: 40px 0 60px 0;
  border-radius: 36px;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__header {
    margin-bottom: 16px;
    @include font-26-bold;
  }

  &__text {
    margin-bottom: 40px;
  }

  @media (max-width: 960px) {
    margin: 0 -20px;
    padding: 66px 20px;

    &__header {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    &__text {
      margin-bottom: 32px;
      @include font-12;
    }
  }
}
