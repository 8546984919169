.loading_done {
  animation: fade-in 0.5s;
  opacity: 100%;
}

@keyframes fade-in {
  from {
    opacity: 20%;
  }
  to {
    opacity: 100%;
  }
}

.loading {
  animation: fade-out 0.5s;
  opacity: 20%;
}

@keyframes fade-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 20%;
  }
}
