@import '/src/styles/helpers/variables.scss';

.icon {
  display: block;

  path,
  g {
    fill: inherit;
  }
}

.left {
  margin-right: 13px;
}

.right {
  margin-left: 13px;
}
