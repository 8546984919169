@import 'styles/main.scss';

.addSolutionContainer {
  margin: $space-4xl auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $space-xl;

  @media screen and (max-width: 1360px) {
    padding: 0 $space-l;
  }

  .formBlockContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $space-xl;

    @media screen and (max-width: $sm) {
      margin-bottom: $space-5xl;
    }
  }

  .title {
    @include font-32-bold;
    position: relative;
    color: var(--text-color);

    @media screen and (max-width: $sm) {
      @include font-24-bold;
    }

    span {
      position: absolute;
      @include font-14-bold;
      color: var(--text-light);
    }
  }

  .stepsBlock {
    display: flex;
    flex-direction: row;
    @include font-16;
    gap: $space-s;

    @media (max-width: $xs) {
      align-items: center;
    }

    .stepLine {
      position: relative;
      width: 48px;
      height: auto;

      &:after {
        position: absolute;
        top: 50%;
        content: '';
        width: 48px;
        height: 1px;
        background: var(--accent-second);
      }

      @media (max-width: $xs) {
        width: 100%;
        bottom: 14px;
        &:after {
          width: 100%;
        }
      }
    }

    .step {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $space-sm;
      width: fit-content;
      cursor: pointer;

      @media (max-width: $xs) {
        flex-direction: column;
      }

      .stepNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #ffffff;
        background: var(--accent-second);
        @include font-16-bold;

        @media (max-width: $sm) {
          width: 24px;
          height: 24px;
          font-size: 14px;
        }
      }

      .stepTitle {
        @include font-14;
        color: var(--text-color);
        white-space: nowrap;

        @media screen and (max-width: $xs) {
          font-size: 12px;
        }
      }

      &.active {
        .stepNumber {
          background: var(--accent-color);

          &__inner {
            width: 90%;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--accent-color);
            border-radius: 50%;
            border: 1px solid white;
          }
        }
      }

      &.done {
        .stepNumber {
          &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--accent-color);
            width: 32px;
            height: 32px;
            padding: $space-xs;
            border-radius: 50%;

            @media (max-width: $sm) {
              width: 24px;
              height: 24px;
            }

            svg {
              width: 24px;
              height: 24px;
            }
          }

          @media (max-width: $sm) {
            padding-left: 0;
          }
        }
      }

      &.notAllowed {
        cursor: not-allowed;
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .addSolutionContainer {
    padding: 0;
    .title {
      padding: 0 $space-l;
    }
    .formBlockContainer {
      padding: $space-xl $space-l;
      border-radius: 24px;
      background: #fff;
      box-shadow: var(--card-shadow);
      margin-bottom: $space-xl;

      &.previewBlock {
        padding: $space-0;

        .stepsBlock {
          margin: $space-xl $space-l $space-l $space-l;
        }
      }

      &.stepTwo {
        @media screen and (max-width: $xs) {
          padding: $space-xl 0;

          .stepsBlock {
            margin: 0 $space-l;
          }
        }
      }
    }
  }
}
