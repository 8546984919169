@import 'styles/helpers/variables.scss';

.wrapper {
  padding: 36px 0;

  @media (max-width: 1024px) {
    padding: 48px 0 110px 0;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0;
  @include font-14;

  span {
    color: var(--text-light);
  }

  &_count {
    @include font-14;
    color: var(--accent-second);
  }
}

.btn {
  padding: 8px 24px;

  @media screen and (max-width: 1024px) {
    position: fixed;
    z-index: 10;
    bottom: 80px;
    padding: 12px 32px;
    @include font-16-bold;
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 680px) {
    width: calc(100% - 40px);
  }
}

.title {
  @include font-32-bold;
  color: var(--text-color);

  span {
    position: absolute;
    color: var(--text-light);
    @include font-12-bold;
  }

  @media screen and (max-width: 1024px) {
    @include font-22-bold;

    span {
      @include font-11-bold;
    }
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  border-radius: 24px;
  background: $white;
  box-shadow: var(--top-shadow);
  padding: 24px 24px 40px 24px;

  @media screen and (max-width: 680px) {
    margin: 24px -20px 0px -20px;
    padding: 24px 20px;
  }
}

.info-wrapper {
  display: flex;
  gap: 16px;

  &__legalEntity {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.empty {
  &__content {
    margin: 0 !important;
    svg {
      width: 96px;
      height: 50px;
    }

    span {
      @include font-12;
    }
  }
}

.hintsTooltip {
  width: 270px;
  max-width: unset;

  &__top--center {
    left: 50% !important;
  }
}
