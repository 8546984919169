@import '/src/styles/helpers/variables.scss';

.search {
  width: 100%;
  @media screen and (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100vh;
    background-color: rgba(13, 9, 24, 0.56);
    backdrop-filter: blur(1.5px);

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__container {
    position: relative;

    .search__close {
      position: absolute;
      top: 16px;
      right: 16px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media screen and (max-width: 1024px) {
      z-index: 1000;
      width: 100%;
      min-height: 465px;
      height: max-content;
      padding: 48px 16px 70px;
      border-radius: 24px 24px 0 0;
      background-color: $white;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 425px;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }

  &__input {
    input {
      padding: 8px 30px 8px 44px;
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid var(--accent-second);
      background-color: var(--accent-light);

      &::placeholder {
        color: var(--text-light);
      }

      &:hover {
        border: 1px solid var(--accent-color);
      }

      @media screen and (max-width: 1024px) {
        height: 44px;
      }
    }
  }

  &__btn {
    position: absolute;
    top: 12px;
    left: 12px;
    background: transparent;
    pointer-events: none;

    svg {
      path {
        fill: var(--text-light);
      }
    }
  }

  &__clear {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      top: 12px;
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    top: 44px;
    z-index: 100;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    background-color: $white;
    box-shadow:
      0px 2.19453px 6.6501px 0px rgba(28, 20, 37, 0.05),
      0px 7.37098px 22.33631px 0px rgba(28, 20, 37, 0.03),
      0px 33px 100px 0px rgba(28, 20, 37, 0.02);

    @media screen and (max-width: 1024px) {
      position: relative;
      top: 5px;
      left: auto;
      box-shadow: none;
      &--wrapper {
        max-height: 400px;
        overflow-y: scroll;
        scrollbar-width: 0px;

        &::-webkit-scrollbar {
          width: 0px;
        }
      }
    }
  }

  &__dropdown-title {
    @include font-12-bold();
  }

  &__dropdown-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin: 12px 0;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__dropdown-item {
    @include font-12();

    a {
      color: var(--text-color);
      transition: color ease-in-out 0.3s;

      &:hover {
        color: var(--accent-color);
      }
    }
  }

  &__see-all {
    @include font-14();
    color: var(--accent-color);
    display: inline-block;
    margin-top: 12px;
  }

  &__not-found {
    margin: 0;
    @include font-12();
    color: var(--text-light);

    span {
      @include font-12-bold();
      color: var(--text-color);
    }
  }

  &__search-text {
    color: var(--accent-color);
  }
}
