.empty {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }
  &__title {
    color: var(--text-light);
    font-size: 14px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__bottom-text {
    color: var(--text-light);
    font-size: 14px;
    margin-top: 5px;
  }
}
